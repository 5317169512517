// --------------------------------------------------
// Btns components
// --------------------------------------------------
.button {
  display: inline-block;
  padding: 20px 35px;
  text-align: center;
  font-weight: 600;
  font-family: $fontBtn;
  background: $colorAccent2;
  border: 2px solid $colorAccent2;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;
  transition: .3s;
  border-radius: 50px;
  transition-duration: .3s;
  -webkit-appearance: none;

  &:hover {
    opacity: 0.75;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      opacity: 0.5;
    }
  }

  &.accent {
    background: $colorAccent1;
    border: 2px solid $colorAccent1;
  }

  &.smaller {
    padding: 6px 0;
    width: 28px;
    text-align: center;
  }

  &.rounded {
    border-radius: 50%;
    background: transparent;
    border: 1px solid $dark;
    font-weight: 500;
    color: $dark;
  }
}

/*
 * 		Button Icon
 * 		-----------
 */

.button-icon {
  display: flex;
  padding: 20px 35px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: none;
  background-color: $black;
  color: $white;
  font-family: $fontBtn;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: background-color .2s ease-in-out;

  &:hover {
    background-color: $colorAccent1;
  }

  &.gray {
    background-color: $light;
    color: $dark;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }

  .button-icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .icon {
    height: 18px;
    width: auto;
    margin-top: -4px;
    margin-right: 15px;
    fill: $white;

    svg {
      fill: $white;
    }
  }

  &.facebook {
    background-color: #3B5998;
    color: $white;

    &:hover {
      background-color: $black;
      color: $white;
    }

    .icon {
      margin-top: -6px;
    }
  }
}
