.orders-header {
	text-transform: uppercase;
	font-family: $fontText;
	color: $dark;
}

.orders-table {
	.table-titles {
		padding: 0 !important;

		justify-content: center;
		// align-items: stretch;
	}

	.table-titles p {
		border: 1px solid rgba($dark, .025);
		padding: 20px;
		font-weight: 600;
		font-family: $fontTitle1;
		color: $dark;
		background: $light;
	}

	.table-item {
		// border: 1px solid rgba($dark, .5);

		&:nth-child(4n), &:nth-child(4n-2) {
			background: rgba($colorAccent2, .1);
			border: 1px solid rgba($dark, .025);
		}

		&:nth-child(4n-1), &:nth-child(4n-3) {
			background: rgba($white, .1);
		}

		.table-item-details {
			justify-content: center;
			align-items: center;

			background: none;
			font-family: $fontText;
			font-weight: 500;
			color: $dark;

			:nth-child(1) {
				text-decoration: underline;
			}
		}
	}
}
