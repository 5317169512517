.shop.shop-orders {

  .table-titles {
    flex-direction: row;
    padding: 10px 20px;

    .main-text {
      width: calc(100% / 5);
      text-align: center;

      @media (max-width: 699px) {
        width: 100%;
      }

       &.no-mobile {
        @media (max-width: 699px) {
          display: none;
        }
      }
    }
  }

  .table-item-details {
    padding: 20px;

    .main-text {
      width: calc(100% / 5);
      text-align: center;

      @media (max-width: 699px) {
        width: 100%;
      }

      &.no-mobile {
        @media (max-width: 699px) {
          display: none;
        }
      }

      &.cancel {
        color: #B50000;
      }

      &.done {
        color: #008C05;
      }

      &.waiting {
        color: $colorAccent1;
      }
    }
  }
}

.order-table {

  .order-table-header {
    -js-display: flex;
    display: flex;
    padding: 0 20px 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid $darker;

    .order-table-header-column {
      width: 120px;
      text-align: center;

      &:first-child {
        flex-grow: 1;
        text-align: left;
      }

      &.no-mobile {
        @media (max-width: 699px) {
          display: none;
        }
      }
    }
  }

  .order-table-item {
    -js-display: flex;
    display: flex;
    padding: 20px;
    background: $white;
    margin-bottom: 20px;

    .order-table-item-column {
      width: 120px;
      text-align: center;

      &:first-child {
        flex-grow: 1;
        text-align: left;
        font-weight: 600;
      }

      &:last-child {
        font-weight: 600;
      }

      &.no-mobile {
        @media (max-width: 699px) {
          display: none;
        }
      }
    }
  }

  .invoice-items {
    @media (min-width: 899px) {
      td {
        padding: 15px 0 !important;
        font-size: 16px !important;
      }

      .discount-row td {
        padding: 0 0 15px !important;
        font-size: 14px !important;
      }
    }
  }
}

.popp002 .sidepanel-content.order-addresses {
  padding: 30px 20px 0;

  h4.title5 {
    margin: 0 0 20px;
  }

  .order-address-panel {
    margin-bottom: 30px;
  }

  .order-address-item {
    -js-display: flex;
    display: flex;
    margin-bottom: 10px;

    i {
      width: 20px;
      text-align: center;
      margin-right: 10px;
      color: $colorAccent1;
      font-size: 18px;
      line-height: 20px;
    }

    .order-address-detail {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: .5px;
    }
  }
}
