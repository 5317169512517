.address-page-title {
	text-transform: uppercase;
	font-family: $fontText;
	color: $dark;
}

.address-cta {
	justify-content: space-around;
	align-items: baseline;
}

.address-add-cta {
	a, input {
		width: auto;
		background: #8bce58;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bce58', endColorstr='#43b953', GradientType=1 );
		font-size: 14px;
		border: none;
		padding: 15px 15px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-weight: bold;
		border-radius: 0;
		color: $white;
		cursor: pointer;

		transition: all ease-in-out .3s;

		&:hover {
    		opacity: 0.75;
		}
	}
}

.address-cancel-cta {
	a {
		width: auto;
		background: $white;
		font-size: 14px;
		border: 2px solid $colorAccent1;;
		padding: 15px 15px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		line-height: 1;
		font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-weight: bold;
		border-radius: 0;
		color: $colorAccent3;;
		cursor: pointer;

		transition: all ease-in-out .3s;

		&:hover {
    		opacity: 0.75;
		}
	}
}

.address-grid {
	display: grid;
  	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  	grid-gap: 20px;
  	align-items: stretch;

	.address-card {
		position: relative;

		border: 1px solid $colorAccent1;
  		box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
  		border-radius: 2px;

  		min-height: 415px;

  		.address-card-header {
			width: 100%;
  		 }

  		.address-card-footer {
  			position: absolute;
  			left: 0;
  			bottom: 0;
			width: 100%;
  		}

  		a {
  			width: 100%;
    		background: #8bce58;
    		background: -webkit-gradient(left top, right top, color-stop(0%, #8bce58), color-stop(34%, #75c857), color-stop(53%, #54be54), color-stop(100%, #43b953));
			background: -webkit-gradient(linear, left top, right top, from(#8bce58), color-stop(34%, #75c857), color-stop(53%, #54be54), to(#43b953));
			background: linear-gradient(to right, #8bce58 0%, #75c857 34%, #54be54 53%, #43b953 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bce58', endColorstr='#43b953', GradientType=1 );
			font-size: 14px;
			border: none;
			padding: 16px 15px;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			line-height: 16px;
			font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-weight: bold;
			border-radius: 0;
  		}

  		input[type="submit"] {
  			width: 100%;
    		background: #FFF;
			font-size: 14px;
			border-top: 1px solid $colorAccent1;
			padding: 16px 15px;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			line-height: 16px;
			font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-weight: bold;
			cursor: pointer;
			color: $colorAccent3;
  		}
	}
}

.address-data {
	width: 100%;
	height: 100%;
	padding: 15px;

	.block {
		background: $light;
		border: 1px solid rgba($white, .022);
		padding: 5px 10px;
		border-radius: 3px;
		color: $dark;
		font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
		margin-bottom: 20px;

		&.input {
			outline: none;
		}

		.separator {
			height: 1px;
			background: $dark;
			width: 50%;
			margin: 10px auto;
		}
	}

	.col {
		justify-content: stretch;

		.left {
			width: 50%;
			margin-right: 5px;
		}

		.right {
			width: 50%;
			margin-left: 5px;
		}
	}
}

