.shop-step {
  margin-top: -25px;
  padding-bottom: 45px;

  .icon-step {
    width: 52px;
    height: 52px;
    padding: 2px;
    margin: 0 15px;
    border-radius: 50%;
    background-color: $white;
    border: 1px solid #C4C3C3;

    @media (max-width: 767px) {
      width: 42px;
      height: 42px;
      margin: 0 10px;
    }

    i {
      color: $black;
      font-size: 22px;

      &.icon-payment {
        font-size: 18px;
      }

      @media (max-width: 767px) {
        font-size: 19px;
      }
    }

    &.active {
      background-color: $colorAccent1;
      border-color: $colorAccent1;

      i {
        color: $white;
      }
    }
  }
}

.steps {

  display: flex;
  width: 100%;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding-top: 0;
    margin-bottom: -50px;
  }

  a {
    color: inherit;
  }

  .steps-wrapper {

    display: flex;
    width: 80%;
    max-width: 1120px;
    padding: 20px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media screen and (min-width: 520px) {

      & {

        flex-direction: row;
      }
    }
  }

  .step {

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 30px;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: $dark;
    font-family: $fontText;

    &:first-child {

      margin-top: 20px;
    }

    @media screen and (min-width: 520px) {

      & {

        margin-bottom: 0;
        padding-top: 35px;
        padding-left: 0;
        justify-content: center;
        text-align: center;
      }

      &:first-child {

        margin-top: 0;
      }
    }

    &:after {

      content: attr(step-index);
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      border: 1px solid $dark;
      font-size: 16px;
      line-height: 22px;
      font-family: $fontCaption;
      color: $dark;
      background-color: $white;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: 520px) {

      //& {
      //
      //  width: calc(33.333% - 30px);
      //}

      &:before {

        content: '';
        position: absolute;
        top: calc(50% - 14px);
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background-color: $dark;
      }

      &:after {

        position: absolute;
        top: calc(50% - 12px);
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:first-child {

      &:before {

        left: 50%;
      }
    }

    &:nth-child(2n) {

      &:before {

        left: 50%;
        width: 50%;
      }

      &.active {
        color: $colorAccent1;

        &:before {

          right: 50%;
          left: calc(-50% + 9px);
          width: 100%;
          border-color: $colorAccent1;
          background-color: $colorAccent1;
          color: $white;
        }
      }
    }

    &:nth-child(3n) {

      &:before {

        right: 50%;
        left: calc(-50% + 9px);
        width: 100%;
      }

      &.active {
        color: $colorAccent1;

        &:before {
          border-color: $colorAccent1;
          background-color: $colorAccent1;
          color: $white;
        }
      }
    }

    &.active {
      color: $colorAccent1;

      &:after {
        border-color: $colorAccent1;
        background-color: $colorAccent1;
        color: $white;
      }
    }
  }

  &.finish {

    .step {

      &.active {

        &:before {

          background-color: $colorAccent1;
        }

        &:after {

          background-color: $colorAccent1;
        }
      }
    }
  }
}

.signup-steps {

  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $light;

  a {
    color: inherit;
  }

  .steps-wrapper {

    display: flex;
    width: 80%;
    max-width: 1120px;
    padding: 20px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 520px) {

      & {

        flex-direction: row;
      }
    }
  }

  .step {

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 30px;
    text-align: left;

    &:first-child {

      margin-top: 20px;
    }

    @media screen and (min-width: 520px) {

      & {

        margin-bottom: 0;
        padding-top: 35px;
        padding-left: 0;
        justify-content: center;
        text-align: center;
      }

      &:first-child {

        margin-top: 0;
      }
    }

    &:after {

      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 4px solid $white;
      background-color: $white;
      transform: translate(-50%, -50%);
    }

    @media screen and (min-width: 520px) {

      & {

        width: calc(33.333% - 30px);
      }

      &:before {

        content: '';
        position: absolute;
        top: calc(50% - 14px);
        left: 0;
        right: 0;
        height: 3px;
        width: 100%;
        background-color: $white;
      }

      &:after {

        position: absolute;
        top: calc(50% - 12px);
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:first-child {

      &:before {

        left: 50%;
      }
    }

    &:nth-child(2n) {

      &:before {

        width: 50%;
      }

      &.active {

        &:before {

          right: 50%;
          left: calc(-50% + 9px);
          width: 100%;
          background-color: $colorAccent1;
        }
      }
    }

    &:nth-child(3n) {

      &:before {

        right: 50%;
        left: calc(-50% + 9px);
        width: 100%;
      }

      &.active {

        &:before {

          background-color: $colorAccent1;
        }
      }
    }

    &.active {

      &:after {

        background-color: $colorAccent1;
      }
    }
  }

  &.finish {

    .step {

      &.active {

        &:before {

          background-color: $colorAccent1;
        }

        &:after {

          background-color: $colorAccent1;
        }
      }
    }
  }
}
