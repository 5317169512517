.shop {
  position: relative;
  z-index: 5;
  min-height: calc(100vh - 200px);
  margin: 0 50px 0;
  padding: 50px 50px 80px; //background-color: #f4f4f4;

  @media (max-width: 767px) {
    padding: 30px 15px 15px;
    margin: 50px 25px 0;
  }

  @media (max-width: 499px) {
    margin: 20px 15px 0;
  }

  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 5px;

      .title3 {
        font-size: 24px;
      }
    }
  }

  .table {
    flex-direction: column;
    width: 1200px;
    max-width: 100%;
    margin: auto;
  }

  .table-item {
    margin-bottom: 10px;

    .table-item-details {
      background-color: $white;
    }
  }
}

.hero-confirmation {
  margin: 0 50px;
  padding: 100px 40px 125px;
  background-image: url('../images/hero-confirmation.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  @media (max-width: 767px) {
    padding: 75px 40px 100px;
    margin: 0 25px;
  }
  @media (max-width: 499px) {
    padding: 50px 40px 75px;
    margin: 0 15px;
  }
  .content {
    text-align: center;
    .title2 {
      margin-bottom: 10px;
    }
    .main-text {
      font-size: 16px;
    }
  }
}

.passes-grid {
  position: relative;
  z-index: 5;
  max-width: calc(1024px + (55px * 2));
  margin: 0 auto;
  padding: 55px 55px 400px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 999px) {
    padding: 45px 25px 300px 25px;
  }

  @media (max-width: 425px) {
    padding: 45px 20px 200px 20px;
  }

  .pass_card {
    width: calc((100% / 3) - 20px);
    vertical-align: top;
    background: $colorAccent2; // display: inline-table;
    display: flex;
    flex-direction: column;
    top: 0;
    position: relative;
    margin-right: 20px;
    margin-bottom: 40px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(159, 159, 159, 0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(159, 159, 159, 0.25);
    box-shadow: 0px 0px 20px 0px rgba(159, 159, 159, 0.25);
    //border-radius: 7px 7px 0 0;

    @media (min-width: 1024px) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (max-width: 1024px) {
      width: calc((100% / 2) - 10px);
      margin-right: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }

    .promotable {
      background-image: url(../images/promotion.png);
      background-repeat: no-repeat;
      background-position-x: center;
      position: absolute;
      top: -12px;
      bottom: 330px;
      left: 0;
      right: 0;
    }

    .pass-header {
      position: relative;
      background: rgb(245, 166, 35);
      color: rgb(255, 255, 255);
      min-height: 150px;
      padding-top: 13px;
      padding-bottom: 20px;
      font-family: $fontTitle2;
      //border-radius: 7px 7px 0 0;

      .pass-card-icon {
        position: relative;
        top: -15px;
        background: $colorAccent2;
        text-align: center;
        height: 63px;
        border-radius: 6px 6px 0 0;
        box-sizing: border-box;
        padding: 14px 0;
        box-shadow: 0px 8px 11px 0px rgba(63, 63, 63, .21);
        -moz-box-shadow: 0px 8px 11px 0px rgba(63, 63, 63, .21);
        -webkit-box-shadow: 0px 8px 11px 0px rgba(63, 63, 63, .21);
        -moz-o-shadow: 0px 8px 11px 0px rgba(63, 63, 63, .21);
        img {
          display: inline-block;
          width: 34px;
          height: 35px;
        }
      }

      .price {
        width: 100%;
        font-size: 44px;
        font-weight: 600;
        display: block;
        text-align: center;
        padding: 0 0 25px;

        &.extra-padding {
          padding: 25px 0 45px 0;
        }

        .dollar-sign {
          font-size: 16px;
          font-weight: 600;
          vertical-align: top;
          font-style: normal;
          margin-left: -9px;
        }
        .tax-tag {
          font-size: 16px;
          position: relative;
          font-style: normal;
          padding-top: 15px; //margin-left: -13px;
          font-weight: 300;
          i {
            position: absolute;
            font-style: normal;
            top: 0;
            display: block;
            text-align: center;
            left: 0;
            right: 0;
          }
        }
      }
      .primary {
        padding: 27px 0 10px;
      }
      .reg-tax-info {
        font-size: 14px;
        font-weight: 600;
        display: block;
        text-align: center;
        padding-bottom: 22px;
      }
      .pas-header-heading {
        color: #ffffff;
        font-size: 16px;
        display: block;
        text-align: center;
        padding: 27px 0 15px;
      }
      .regular-price {
        width: 100%;
        font-size: 14px;
        letter-spacing: -0.0085;
        font-family: $fontTitle2;
        display: block;
        text-align: center;
        text-decoration: line-through;
        // padding: 0 0 50px;
        margin-top: -20px;
      }
    }
    .pass-footer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 10px 25px;
      .features {
        list-style: none;
        text-align: left;
        color: rgb(138, 138, 138);
        margin: 0;
        padding: 0;
        text-align: center;
        .title {
          color: $white;
          font-size: 24px;
          line-height: 40px;
          min-height: 45px;
          text-transform: uppercase;
          display: block;
          font-family: $fontTitle1;
          font-weight: bold;
          letter-spacing: 0.025em;
        }
        p {
          min-height: 65px;
          color: $white;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.0115px;
          margin-bottom: 35px;
          font-family: $fontText;
        }
      }

      .button {
        width: 100%;
      }
      //.button {
      //  width: 100%;
      //  background: rgba(139,206,88,1);
      //  background: -moz-linear-gradient(left, rgba(139,206,88,1) 0%, rgba(117,200,87,1) 34%, rgba(84,190,84,1) 53%, rgba(67,185,83,1) 100%);
      //  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(139,206,88,1)), color-stop(34%, rgba(117,200,87,1)), color-stop(53%, rgba(84,190,84,1)), color-stop(100%, rgba(67,185,83,1)));
      //  background: -webkit-linear-gradient(left, rgba(139,206,88,1) 0%, rgba(117,200,87,1) 34%, rgba(84,190,84,1) 53%, rgba(67,185,83,1) 100%);
      //  background: -o-linear-gradient(left, rgba(139,206,88,1) 0%, rgba(117,200,87,1) 34%, rgba(84,190,84,1) 53%, rgba(67,185,83,1) 100%);
      //  background: -ms-linear-gradient(left, rgba(139,206,88,1) 0%, rgba(117,200,87,1) 34%, rgba(84,190,84,1) 53%, rgba(67,185,83,1) 100%);
      //  background: linear-gradient(to right, rgba(139,206,88,1) 0%, rgba(117,200,87,1) 34%, rgba(84,190,84,1) 53%, rgba(67,185,83,1) 100%);
      //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bce58', endColorstr='#43b953', GradientType=1 );
      //  font-size: 14px;
      //  border: none;
      //  padding: 16px 15px;
      //  text-transform: uppercase;
      //  letter-spacing: 0.1em;
      //  line-height: 16px;
      //  font-family: $fontText;
      //  font-weight: bold;
      //}
    }
  }
  .load-more {
    display: inline-block;
    padding: 30px 0;
    line-height: 1;
    text-align: center;
    font-weight: 600;
    border: 2px solid #5e5f61;
    color: #3F3F3F;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 5px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-appearance: none;
    width: 100%;
    text-transform: uppercase;
  }
  .load-more:hover {
    background-color: #5e5f61;
    color: #fff;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 10px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-appearance: none;
  }
}

.forfaits-grid {
  display: none;

  &.is-active {
    display: flex;
  }
}

.popular-products {
  z-index: 5;
  //background-color: #F7F9FB;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 500px;

  h3 {
    text-align: center;
    padding-top: 75px;
    font-size: 36px;
    font-weight: 300;
    color: $colorAccent2;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: $fontTitle2;
    line-height: 27px;
    letter-spacing: -0.6px;

    @media (max-width: 767px) {
      padding-top: 45px;
    }
  }
  .passes-grid {
    padding-bottom: 0;
  }
}

.passes-details {
  position: relative;
  z-index: 5;
  height: 100%;
  max-width: 1109px;
  margin: 0 auto;
  padding: 75px 0 300px;

  @media screen and (max-width: 1109px) {
    padding: 35px 0 300px;
  }

  &.half-columns {
    @media (min-width: 768px) {
      .col.half {
        width: 60%;
      }
      .col.half:last-child {
        width: 40%;
      }
    }
  }

  .col {
    float: left;
  }
  .col.two-third {
    width: 66.66%;
    padding-right: 132px;

    @media screen and (max-width: 1109px) {
      padding-right: 60px;
    }
    @media(max-width:767px) {
      width: 100%;
      padding-right: 0;
    }
  }
  .col.one-third {
    width: 33.33%;
    @media(max-width: 767px) {
      width: 100%;
    }
  }

  .col.half {
    width: 50%;

    @media(max-width: 767px) {
      width: 100%;
    }

    &:first-of-type {
      padding-right: 123px;

      @media screen and (max-width: 1109px) {
        padding-right: 60px;
      }
      @media(max-width: 767px) {
        padding-right: 0;
      }
    }
  }
  .header {
    margin-bottom: 28px;
    overflow: hidden;
    background: $white;

    .img-box {
      width: 20%;
      float: left;
      img {
        width: 90px;
      }
    }
    .title {
      width: 100%;
      float: left;
      h1 {
        color: $black;
        font-family: $fontTitle2;
        font-size: 36px;
        font-weight: 300;
        margin-bottom: 0;
        margin-top: 10px;
        letter-spacing: -0.6px;
        @media screen and (max-width: 1109px) {
          font-size: 32px;
        }
      }
      p {
        color: #7AC143;
        font-weight: 300;
        font-size: 18px;
        margin-top: 18px;
        margin-bottom: 20px;
        letter-spacing: 0.6px;
      }
    }
  }
  .note {
    padding: 32px 60px 29px 40px;
    box-shadow: 0 2px 10px rgba(159, 159, 159, .32);
    margin: 40px 0 60px;

    p {
      color: $black;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
      margin: 0;
      letter-spacing: -0.3px;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
    //@media screen and (max-width: 1109px) {
      //padding: 32px 35px 29px 35px;
    //}
    @media screen and (max-width: 767px) {
    //  padding: 15px;

      p {
        line-height: 1.2;
      }
    }
  }
  .body {
    color: $black;

    h6 {
      font-family: $fontTitle1;
      margin-top: 40px;
      margin-bottom: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      text-transform: uppercase;
      line-height: 30px;
      color: $black;

      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
    }

    p {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: $black;
      margin: 0;
      letter-spacing: -0.2px;

      strong {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
      }
    }
    h2 {
      font-family: $fontTitle1;
      font-style: normal;
      font-weight: bold;
      color: $buttonColor;
      font-size: 18px;
    }

    ul,
    ol {
      counter-reset: li;
      list-style-type: none;
      //font-size: 14px;
      //line-height: 18px;
      padding-left: 0;
      color: $black;

      li {
        position: relative;
        padding: 0 0 13px 18px;
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.2px;

        &:before {
          content: '';
          height: 6px;
          width: 7px;
          background-color: $black;
          border-radius: 50%;
          color: #FFFFFF;
          text-align: center;
          position: absolute;
          line-height: 12px;
          left: 0;
          top: 11px;
        }
      }
    }

    ol {
      li {
        padding: 0 0 13px 36px;

        &:before {
          content: counter(li);
          counter-increment: li;
          height: 23px;
          width: 24px;
          line-height: 23px;
          left: 0;
          top: 6px;
        }
      }
    }
  }
  .sidebar {
    p.sidebar-heading {
      background: $newGrey;
      color: $black;
      padding: 20px;
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      filter: drop-shadow(0px 2px 4px rgba(159, 159, 159, 0.608809));
      font-family: $fontTitle1;
      //@media screen and (max-width: 1109px) {
      //  padding: 38px 30px;
      //}
    }
    .sidebar-form {
      border: 2px solid #F2F2F2;
      background: $white;
      .variant {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px #ededed solid;
        padding: 25px;
        overflow: hidden;

        .variant-thumb {
          display: flex;
          align-items: center;
          padding: 15px;
          //border: 1px dashed #dddddd;
          margin-right: 15px;
          background: #fff;
          max-width: 97px;

          img {
            width: 100px;
            max-width: 100%;
            height: auto;
          }

          i {
            display: block;
            height: auto;
            color: #fff;
            font-size: 45px;
            line-height: 1;
          }
        }

        .line-item-details {
          flex-direction: column;
          padding: 10px 0;

          .title4 {
            font-family: $fontTitle1;
            margin: 0;
            padding: 0;
            font-size: 20px;
            text-transform: none;
            color: $black;
            font-weight: bold;
          }

          .main-text {
            font-size: 12px;
            padding: 0;
            margin: 0;
          }
        }

        //.input-number {
        //  position: relative;
        //  left: 0;
        //  width: 100%;
        //  height: 100%;
        //  min-height: 50px;
        //  max-width: 75px;
        //  border: none;
        //  text-align: center;
        //  padding: 10px 5px;
        //  font-weight: 600;
        //  outline: none;
        //  font-size: 20px;
        //
        //  &::-webkit-inner-spin-button,
        //  &::-webkit-outer-spin-button {
        //    opacity: 1;
        //  }
        //}

        .cart-quantity {
          justify-content: space-between;
          margin-top: 15px;

          .in-cart {
            margin-right: 10px;

            input[type=number] {
              text-align: center;
              border: none;
              background: transparent;
              padding: 1px 1px 0 0;
              margin: 0 4px;
              font-size: 22px;
              font-weight: bold;

              &::-webkit-inner-spin-button,
              &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }

            .btn-number {
              width: 25px;
              height: 25px;
              font-size: 20px;
              font-family: $fontTitle1;
              font-weight: 500;
              outline: none;
              cursor: pointer;
              color: $white;
              border: 1px solid $colorAccent1;
              border-radius: 1px;

              background: #8bce58;
              background: -webkit-gradient(left top, right top, color-stop(0%, #8bce58), color-stop(34%, #75c857), color-stop(53%, #54be54), color-stop(100%, #43b953));
              background: -webkit-gradient(linear, left top, right top, from(#8bce58), color-stop(34%, #75c857), color-stop(53%, #54be54), to(#43b953));
              background: linear-gradient(to right, #8bce58 0%, #75c857 34%, #54be54 53%, #43b953 100%);
              filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8bce58', endColorstr='#43b953', GradientType=1);

              -webkit-transition: all 0.2s ease-in-out;
              transition: all 0.2s ease-in-out;

              &:hover {
                background: none;
                color: $colorAccent1;
              }
            }
          }

          .price {
            font-family: $fontTitle1;
            font-weight: 500;
            font-size: 24px;
            line-height: 22px;
            text-align: center;
            letter-spacing: 0.4625px;
            color: $black;

            &.is-onsale {
              display: flex;
              flex-direction: column;

              small {
                font-size: 12px;
                text-decoration: line-through;
              }
            }
          }
        }

        //.variant-detail {
        //  width: 75%;
        //  float: left;
        //  .title {
        //    color: #C7C7CC;
        //    font-size: 18px;
        //    line-height: 20px;
        //    margin: 0;
        //    font-weight: normal;
        //    font-family: $fontTitle1;
        //  }
        //  .price {
        //    font-size: 24px;
        //    color: #3F3F3F;
        //    line-height: 22px;
        //    margin-bottom: 2px;
        //    letter-spacing: -0.0165em;
        //    font-weight: normal;
        //    margin-top: 13px;
        //    font-family: $fontText;
        //
        //    .dollar-sign {
        //      margin-left: -9px;
        //    }
        //  }
        //  .regular {
        //    font-size: 14px;
        //    line-height: 24px;
        //    color: #9F9F9F;
        //    text-decoration: line-through;
        //    margin: 0;
        //  }
        //  .variant-offer-per {
        //    font-size: 24px;
        //    line-height: 22px;
        //    color: #40B972;
        //    font-weight: normal;
        //    font-family: $fontText;
        //    display: inline-block;
        //    margin-left: 15px;
        //    @media screen and (max-width: 1109px) {
        //      margin-left: 3px;
        //    }
        //  }
        //}
        //.variant-qty {
        //  width: 52px;
        //  float: right;
        //  overflow: hidden;
        //  border-radius: 5px;
        //  background-color: #3F3F3F;
        //  background-image: url(../images/arrow.png);
        //  background-repeat: no-repeat;
        //  background-position: 71% 53%;
        //  background-size: 10px;
        //  padding: 0 0 0 3px;
        //  margin-top: 4px;
        //  border: 1px solid #979797;
        //  select {
        //    background: transparent;
        //    width: 52px;
        //    padding: 10px;
        //    font-size: 18px;
        //    font-weight: 600;
        //    outline: 0;
        //    line-height: 1;
        //    border: 0;
        //    border-radius: 0;
        //    height: 45px;
        //    -webkit-appearance: none;
        //    color: #bebebe;
        //  }
        //}
        @media screen and (max-width: 1109px) {
          padding: 28px 15px 30px 15px;
        }
      }
      .sub-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F1F2F5;
        padding: 28px 30px;
        overflow: hidden;
        margin: 0 2px;
        //border-bottom: 1px #ededed solid;
        .title {
          width: 50%;
          float: left;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 28px;
          text-transform:uppercase;
          font-family: $fontTitle1;
          margin: 0;
          letter-spacing: 0.2em;
          color: $black;
        }
        .price {
          font-family: $fontTitle1;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 20px;
          letter-spacing: 0.4625px;
          width: 50%;
          text-align: right;
          white-space: nowrap;
          float: left;
          margin: 0;
          color: $black;

          .dollar-sign {
            margin-left: -9px;
          }
        }
      }
      .sidebar-button {
        text-align: center;
        padding: 20px 0;
        margin-top: 0;
        p.discount {
          font-size: 14px;
          color: #3f3f3f;
          white-space: nowrap;
          line-height: 18px;
          margin-top: 0;
        }

        .minimum-require {
          font-family: $fontText;
          font-weight:bold;
          color: $black;
        }

        button.button {
          width: 80%;
          font-weight: bold;
          font-family: $fontTitle1;
          border: none;
          letter-spacing: 0.0585em;
          color: #fff;
          font-size: 14px;
          text-transform: uppercase;
          padding: 20px 40px;
          //box-shadow: 0 2px 4px rgba(159, 159, 159, .61);
          background-color: $buttonColor;
          filter: drop-shadow(0px 2px 4px rgba(159, 159, 159, 0.608809));

          &[disabled] {
            opacity: .5;
            filter: grayscale(1);
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1109px) {
    padding: 50px 20px;
  }
}

/*05-07*/

.section-intro {
  border-bottom: 2px solid rgba(0, 0, 0, .08);

  &.no-border {
    border-bottom: none;
  }
}

.footer {
  border-top: 2px solid rgba(0, 0, 0, .08);
}

.bg-grey {
  background: #F7F9FB;
}

.terms {
  .bottom-background {
    opacity: .2;
  }
}

.bg-white {
  background: #fff;
}

.shop-form {
  padding-bottom: 450px;

  .login-section {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .login-title {
    margin: 0 0 25px;
    font-family: $fontText;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
  }
}

.checkout-login {
  &.container {
    margin: 20px auto 10px auto;
    max-width: 480px;
    width: 100%;
    display: flex;
    flex-direction: column;
    //align-items: center;
  }

  label:not(.checkbox) {
    text-transform: uppercase;
  }

  .button {
    width: 100%;
  }

  p {
    width: 100%;
    text-align: center
  }
}



// --------------------------------------------------
// Block - Accordion
// --------------------------------------------------

.block-accordion {
  .icon-ticket {
    font-size: 20px;
    line-height: 1;
    margin-right: 15px;
    color: $colorAccent1;
  }
}

.block-code-promo {
  margin-bottom: 20px;

  .promo-wrapper {
    display: flex;
    align-items: center;

    input {
      margin-bottom: 0;
    }

    .button {
      margin: 0 0 0 20px;
      max-width: fit-content;
    }
  }
}

.block-accordion > ul {
  list-style: none;
  padding: 0;
}

.block-accordion-label {
  position: relative;
  margin: 0;
  padding: 16px 60px 14px 24px;

  border-top: 1px #d1d1d1 solid;
  border-right: 1px #d1d1d1 solid;
  border-left: 1px #d1d1d1 solid;
  background: #f6f6f6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.89px;
  line-height: 22px;
  color: #21263a;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  i {
    color: $colorAccent1;
  }

  .i-plus {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate3d(0, -50%, 0);
  }

  .i-minus {
    display: none;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translate3d(0, -50%, 0);
  }

  .error-label {
    display: none;
    float: right;
    color: #DA620C;
    font-size: 12px;
  }
}

.block-accordion-row {
  &.is-active {
    .i-plus {
      display: none;
    }
    .i-plus.i-minus {
      display: block;
    }
  }

  &:first-child .block-accordion-label {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child .block-accordion-label,
  &:last-child .block-accordion-content {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom: 1px #d1d1d1 solid;
  }

  &:last-child .block-accordion-content {
    border-top: none;
  }
}

.block-accordion-content {
  display: none;
  padding: 24px 24px 22px;
  border: 1px #d1d1d1 solid;
  border-bottom: none;
  background: $white;

  img {
    max-width: 100%;
  }

  .button,
  .button:visited {
    color: #fff;
  }

  .block-text-table {
    width: 100%;

    img {
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }

  .parsley-errors-list {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-left: 10px;

    li {
      color: #DA620C;
    }
  }
}

.members-zone {
  .shop {
    @media (max-width: 499px) {
      padding: 0 10px;
    }
  }
}

.location-dots {
  display: block;
  width: 6px;
  margin: 40px auto;

  @media (max-width: 767px) {
    width: 4px;
    margin: 20px auto;
  }
}

.location-title {
  margin: 0 auto 25px;
  font-family: $fontTitle2;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  color: $colorAccent2;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 27px;
  }
}

.location-text {
  margin: 0;
  font-family: $fontTitle3;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #7E7E7E;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.section-location {
  position: relative;
  z-index: 1;

  &.is-hidden {
    .location-card {
      opacity: .4;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        background: transparent;
      }
    }

    .location-title,
    .location-text {
      color: rgba(#030000, .4);
    }
  }

  .location-grid {
    //@extend .site-padding;

    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    padding-top: 75px;
    //padding-bottom: 450px;

    @media (max-width: 665px) {
      padding-top: 35px;
      //padding-bottom: 180px;
    }
  }

  .location-grid-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.location-card {
  position: relative;
  height: auto;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;

  background: #fff;

  &.big {
    width: calc(50% - 15px);

    @media(max-width: 899px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &.small {
    width: calc((100% / 3) - (30px * 2 / 3));

    @media(max-width: 899px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  .header-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 13px;
    background: #3f3f3f;

    svg {
      width: 40px;
    }
  }

  .header-visual {
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 767px) {
      height: 200px;
    }
  }

  p {
    margin: 0;
  }

  .card-body {
    padding: 55px 40px 40px;
    background: #030000;
  }

  .body-title {
    position: relative;
    color: $white;
    font-family: $fontTitle5;
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 35px;
      height: 4px;
      background: $colorAccent1;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .body-text {
    margin-top: 30px;
    margin-bottom: 20px;
    color: $white;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;

    &.rental-skigo-notice {
      display: none;
      color: $colorAccent4;
      margin-top: 5px;
      line-height: inherit;
    }

    &.no-tickets {
      color: $colorAccent4;
      margin-top: 0;
    }

    &.is-full {
      display: none;
      color: $colorAccent4;
      margin-top: 0;
      text-transform: capitalize;
    }

    strong {
      font-weight: 800;
    }

    span {
      color: $colorAccent1;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
    }
  }

  .body-btn {
    display: inline-block;
    width: 100%;
    padding: 15px 35px;
    font-family: $fontBtn;
    font-size: 14px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.4625px;
    text-transform: uppercase;
    color: $white;
    border-radius: 25px;
    border: 1px solid $colorAccent1;
    background: $colorAccent1;
    transition: all .4s ease;

    &:hover {
      color: $colorAccent1;
      background: $white;
    }

    @media (max-width: 767px) {
      max-width: 50%;
      margin: 0 auto;
      font-size: 12px;
      line-height: 11px;
    }
  }

  .btn-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;

    @media (max-width: 999px) {
      flex-direction: column;
    }

    .choices-qty {
      border: 1px solid rgba(0, 0, 0, .08);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: none;
      width: 25%;
      text-align: center;
      outline: none;
    }

    button {
      position: relative;
      width: 50%;
      outline: 0;
      cursor: pointer;

      @media (max-width: 999px) {
        width: 100%;
      }

    }

    .item-price {
      display: flex;
      align-items: center;

      @media (max-width: 999px) {
        justify-content: center;
        margin-top: 20px;
      }

      p {
        font-family: $fontText;
        font-size: 18px;
        line-height: 30px;
        color: $white;
        text-transform: uppercase;

        &:first-of-type {
          margin-right: 10px;
          color: #7E7E7E;
        }

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }

  .choices-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .choices-select {
      width: 100%;
      height: 40px;
      padding: 5px 10px;
      margin-bottom: 30px;
      margin-right: 20px;
      border: 0;
      border-bottom: 2px solid #231F20;
      background-color: transparent;
      //border-radius: 3px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: $white;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      outline: none;

      &:last-of-type {
        margin-right: 0;
      }

      .full-select {
        &:after {
          content: 'COMPLET';
          display: inline-block;
          margin-left: 10px;
        }

        &.fr {
          &:after {
            content: 'COMPLET';
          }
        }

        &.en {
          &:after {
            content: 'FULL';
          }
        }
      }

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

.location-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(#000, .33);

  &.is-active {
    display: flex;
  }
//HERE
  .modal-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    background: $white;

    p {
      margin: 0;
    }
  }

  .modal-close {
    position: absolute;
    top: 50%;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .4s ease;
    transform: translateY(-50%);

    svg {
      width: 20px;
      fill: $white;
    }
  }

  .modal-header {
    position: relative;
    padding: 20px 0;
    font-family: $fontTitle1;
    font-style: normal;
    font-weight: bold;
    font-size: 22.5676px;
    line-height: 30px;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    background: #030000;
  }

  .modal-body {
    position: relative;
    overflow-y: auto;
    padding: 60px 40px;

    @media (max-width: 900px) {
      overflow: auto;
      display: flex;
      align-items: stretch;
      height: auto;
      padding: 30px 40px;
    }

    @media (max-width: 425px) {
      padding: 30px 15px;
    }

    .location-form-pickup,
    .location-form-metrics {
      display: flex;
      justify-content: center;
    }

    .location-form-pickup {
      width: 100%;
      max-width: 460px;
      margin: 0 auto;
    }

    .form-group {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .form-label {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-transform: uppercase;
      color: #030000;
    }

    .form-select-wrapper {
      padding: 15px;
      background: $light;
    }

    .form-select {
      width: 100%;
      border: 0;
      background: transparent;
    }
  }

  .modal-btn {
    width: 100%;
    padding: 25px 0;
    border: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.347917px;
    text-transform: uppercase;
    color: $white;
    background: #ACACAC;
    cursor: pointer;
  }

  .parsley-errors-list {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 12px;
    padding: 15px 0;
    margin: 0;
    list-style-type: none;
    color: #b50000;
  }
}
