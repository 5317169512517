// --------------------------------------------------
// Content
// --------------------------------------------------

.section-newsfeed {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    .newsfeed-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        width: 100%;
        padding: 0 25px;
    }
}

.block-text {
    p, ul li {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: .29px;
    line-height: 22px;
    color: #1f3249;
    }

    p > b, strong {
        font-weight: bold;
    }
}

.addressBox .row.js-show-extra {
  display: none;

  &.is-visible {
    display: flex;
  }
}
