.columns {
    position: relative;
}

.addressBox {

    .row {
        -js-display: flex;
        display: flex;

        @media (max-width: 599px) {
          flex-direction: column;
        }

        .columns {
            position: relative;
            width: 100%;

            &.six {
                width: calc(50% - 5px);
                margin-right: 10px;

                @media (max-width: 599px) {
                  width: 100%;
                  margin: 0;
                }
            }

            &.three {
                width: calc(33.33334% - 5px);
                margin-right: 10px;

                @media (max-width: 599px) {
                  width: 100%;
                  margin: 0;
                }
            }

            &.nine {
                width: calc(66.66667% - 5px);
                margin-right: 10px;

              &:nth-child(2n+2) {
                margin-right: 10px;
              }

              &:nth-child(3n+3) {
                margin-right: 0;
              }


                @media (max-width: 599px) {
                  width: 100%;
                  margin: 0 !important;
                }
            }

            &:nth-child(2n+2) {
                margin-right: 0;
            }

            label {
                font-size: 16px;
                line-height: 30px;
                letter-spacing: .5px;
                margin-bottom: 2px;

                @media (max-width: 599px) {
                  font-size: 14px;
                }
            }
        }
    }
}


.addressBox#paymentForm {
  label {
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .row {
    margin-bottom: 22px;
  }

  .columns {
    &.six {
      width: calc(50% - 20px);
      margin-right: 40px;

      @media (max-width: 599px) {
        width: 100%;
        margin: 0;
      }
    }


    &.nine {
      width: calc(66.66667% - 20px);
      margin-right: 40px;

      &:nth-child(2n+2) {
        margin-right: 40px;
      }

      &:nth-child(3n+3) {
        margin-right: 0;
      }


      @media (max-width: 599px) {
        width: 100%;
        margin: 0 !important;
      }
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }

  .parsley-error {
    background: rgba(255, 81, 82, 0.1);
    color: #FF5152;
  }
}



#errors .error-payment,
#transaction-errors p{
  font-family: $fontTitle3;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #FF5152;
}
