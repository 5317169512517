.hidden {
  display: none;
}

form .error-message {
  color: #ff4148;
}

.main-form {
  padding: 25px 0 0 0;
  form {
    width: 100%;
    flex-flow: row wrap; // margin-left: -5px;
    // width: calc(100% + 10px);
  }
  .form-errors {
    display: none;
  }
  .form-field,
  .form-input {
    position: relative;
    display: flex;
    width: calc(100% - 10px);
    margin: 0 5px;
    &.half {
      width: calc(50% - 10px);
    }
    &.one-third {
      width: calc((100% / 3) - 10px);
    }
    &.two-third {
      width: calc((100% / 3 * 2) - 10px);
    }
    &.one-fourth {
      width: calc((100% / 4) - 10px);
    }
    &.three-fourth {
      width: calc((100% / 4 * 3) - 10px);
    }
    &:last-child,
    &.last {
      margin-right: 0;
    }
  }
  input,
  select,
  textarea {
    width: 100%;
    //height: 40px;
    padding: 12px 20px;
    margin-bottom: 10px;
    border: 0;
    background-color: #fff;
    //border-radius: 3px;
    letter-spacing: 0.05em;
    color: $dark;
    font-family: $fontText;
    font-size: 18px;
    line-height: 28px;
    outline: none;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #bebebe;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #bebebe;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #bebebe;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #bebebe;
    }
  }
  label {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: $colorAccent2;
    font-family: $fontTitle2;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 8px;
    margin-top: 5px;

    span, p {
      display: block;
      margin-bottom: 2px;
      font-size: 14px;
      cursor: initial;

      a {
        color: $colorAccent1;
      }
    }
    &.radio,
    &.checkbox {
      line-height: 28px;
      font-family: $fontText;
      text-emphasis: none;

      input {
        width: auto;
        height: auto;
        display: inline-block;
      }
      span {
        margin-left: 10px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
  textarea {
    height: 175px;
    min-height: 175px;
    max-width: 100%;
    min-width: 100%;
    resize: none;
  }
  input[type="submit"],
  button {
    outline: none;
    margin: 20px 5px 0;
  }
  .payment-errors {
    color: #e74c3c;
  }
  input.parsley-success,
  select.parsley-success,
  textarea.parsley-success {
    color: #3a3a3a;
  }
  input.parsley-error,
  select.parsley-error,
  textarea.parsley-error {
    color: #DA620C;
  }
  .has-error {
    input,
    select {
      background: #e74c3c;
    }
  }
  .parsley-errors-list {
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #DA620C;
    margin-left: 10px;
  }
}

.main-form .numberDaySection {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7.5px;

  .checkbox-label {
    margin-left: 1% !important;
    margin-right: 1% !important;

    &:first-child {
      margin-left: 1% !important;
      margin-right: 1% !important;
    }
  }

  label {
    width: 115px;
    max-width: 115px;
    min-width: 115px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: .5px;
    margin-bottom: 2px;

    &.is-fw {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    @media (max-width: 599px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      font-size: 14px;
    }
  }

  @media (max-width: 654px) {
    flex-wrap: wrap;

    .checkbox-label,
    .radio-label {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.numberDaySection .is-checked {
  border-color: $colorAccent1 !important;
}

.main-form .checkbox-label,
.main-form .radio-label {
  -js-display: flex;
  display: flex;
  //background: rgba(0, 0, 0, .022);
  //border: 1px solid rgba(0, 0, 0, .022);
  margin: 10px;
  padding: 5px 10px;
  cursor: pointer;
  font-family: $fontText;
  font-size: 16px;
  line-height: 180%;
  border-radius: 3px;

  p {
    margin: 0;
  }

  &.option {
    margin: 10px 0;

    &.shipping {
      display: flex;
      justify-content: space-between;

      .label-body {
        width: inherit;
      }

      span:last-child {
        line-height: 2;
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  input[type=checkbox],
  input[type=radio] {
    display: inline-block;
    width: 22px;
    height: 26px;
    margin-bottom: 0;
    margin-right: 5px;
  }
  span.label-body {
    display: inline-block;
    line-height: 26px;
    margin-bottom: 0;
    color: $colorAccent2;

    strong {
      text-transform: uppercase;
    }

    .price {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.shop.shop-form .content form .site-btn {
  font-weight: bold;
  font-family: $fontTitle1;
  border: none;
  letter-spacing: 0.0585em;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 20px 40px;
  //box-shadow: 0 2px 4px rgba(159, 159, 159, .61);
  background-color: $buttonColor;
  filter: drop-shadow(0px 2px 4px rgba(159, 159, 159, 0.608809));
  line-height: 13px;
  cursor: pointer;
  transition: .3s;
  border-radius: 50px;
  transition-duration: .3s;
  -webkit-appearance: none;
  &:hover {
    opacity: .75;
  }

  &.signup-btn {
    width: 100%;
  }
}
