.popp002 {
  position: relative;
  overflow: hidden;
  width: 425px;
  max-width: 100%;
  padding: 0;

  &.sidepanel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100vh;
    overflow-y: scroll;
    transform: translate(100%, 0);
    transition: all 0.3s ease-in;
    background-color: $white;
  }

  &.opened {
    z-index: 100;
    transform: translate(0, 0);
  }

  .sidepanel-header {
    position: relative;
    padding: 20px 25px;
    background-color: $newGrey;
    filter: drop-shadow(0px 2px 4px rgba(159, 159, 159, 0.608809));

    .sidepanel-close {
      margin-bottom: 10px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(0, -50%);
      z-index: 999;

      .main-text {
        font-size: 32px;
        cursor: pointer;
        color: $black;
      }
    }

    .vertical-center {
      top: auto;
      transform: none;
    }

    .cart-qty {
      position: relative;
      width: 32px;
      height: 32px;
      margin-left: 10px;
      border-radius: 50%;
      background: $buttonColor;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 24px;
        line-height: 40px;
        color: $white;
        font-family: $fontTitle1;
        transform: translate(-50%, -50%);
      }
    }

    .title3 {
      font-family: $fontTitle1;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 40px;
      color: $black;
      margin: 0;
    }
  }

  .sidepanel-content {
    padding: 0;
  }

  .refresh-zone {
    //background: $light;
  }

  .item-group-by {
    position: relative;
    //background: rgba(0, 0, 0, .025);
    // border-top: 1px solid rgba(0, 0, 0, .05);
    // border-bottom: 1px solid rgba(0, 0, 0, .05);
    // margin-bottom: 15px;
    padding: 15px;

    &:nth-child(even) {
      background: transparent;
    }

    .group-title {
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
      color: $colorAccent2;
      padding: 0;
      margin-top: 0;

      strong {
        display: block;
        text-transform: capitalize;
        font-family: $fontTitle2;
      }
    }
  }

  .ticket-remove-from-cart {
    position: absolute;
    top: 15px;
    right: 15px;

    .button.remove {
      background: #f1585d;
      border-color: #f1585d;
      outline: none;
      padding: 5px
    }
  }

  .line-item {
    margin: 0 0 15px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.for-skishop {

      .line-item-thumb {
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }

      .line-item-subtitle {
        color: $dark;
        font-family: $fontText;
        font-size: 18px;
        line-height: 28px;
      }
    }

    .line-item-thumb {
      display: flex;
      align-items: center;
      padding: 15px;
      //border: 1px dashed #dddddd;
      margin-right: 15px;
      background: #fff;
      max-width: 97px;

      img {
        width: 100px;
        max-width: 100%;
        height: auto;
      }

      i {
        display: block;
        height: auto;
        color: #fff;
        font-size: 45px;
        line-height: 1;
      }
    }

    .line-item-details {
      flex-direction: column;
      padding: 10px 0;

      .title4 {
        font-family: $fontTitle1;
        margin: 0;
        padding: 0;
        font-size: 20px;
        text-transform: none;
        color: $black;
        font-weight: bold;

        small {
          margin-top: 3px;
          color: $black;
          font-size: 14px;
        }
      }

      .main-text {
        font-size: 12px;
        padding: 0;
        margin: 0;
      }
    }

    .cart-quantity {
      margin-top: 15px;

      .price {
        display: flex;
        flex-direction: column;
        font-family: $fontTitle1;
        font-weight: 500;
        font-size: 24px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.4625px;
        color: $black;

        small {
          font-size: 12px;
          text-decoration: line-through;

          &.og-price {
            font-size: 9px;
          }

          &.coupon-sale {
            text-align: right;
            margin-top: 7px;
            text-decoration: none;
            color: $colorAccent4;

            span {
              font-size: 9px;
            }
          }
        }
      }
    }
  }

  .sidepanel-footer {
    padding: 0 3px;

    .title3 {
      font-family: $fontTitle1;
      font-size: 18px;
      line-height: 17px;
      text-transform: uppercase;
    }

    .title4 {
      width: 50%;
      float: left;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      text-transform:uppercase;
      font-family: $fontTitle1;
      margin: 15px 0;
      letter-spacing: 0.2em;
      color: $black;

      &.coupon-title {
        .coupon-price {
          font-size: 12px;
        }
      }
    }

    .subtotal-coupon {
      flex-direction: column;
      color: $colorAccent4;
    }

    .price {
      font-family: $fontTitle1;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;
      letter-spacing: 0.4625px;
      width: 50%;
      text-align: right;
      white-space: nowrap;
      float: left;
      margin: 0;
      color: $black;

      &.subtotal-coupon-price {
        color: $colorAccent4;
      }
    }

    .select-box {
      margin-left: 5px;
      font-size: 13px;
    }

    .vertical-center {
      top: auto;
      transform: none;
    }
  }

  .sidepanel-footer-subtotal {
    padding: 10px 30px;
    border-top: 1px solid #E6E6E6;
    background: $newGrey;
  }

  .sidepanel-footer-total {
    padding: 15px 30px;
    border-top: 1px solid #E6E6E6;

    .price {
      color: $black;
    }
  }

  .sidepanel-footer-cta {
    padding: 15px 0;
    text-align: center;

    .button {
      width: 80%;
      font-family: $fontTitle1;
      border: none;
      letter-spacing: 0.0585em;
      font-size: 14px;
      text-transform: uppercase;
      padding: 20px 40px;

      background-color: $buttonColor;
      color: white;
      font-weight: 700;
      &.black {
        background-color: $black;
        color: white;
      }
      &:first-child {
        margin-bottom: 30px;
      }

      &.is-disabled {
        cursor: auto;
        opacity: 0.5;
      }
    }
  }

  .quantity {
    align-items: center;
    width: 100%;

    &.is-onsale {
      width: 48%;

      &.qty-sale {
        width: 100%;
        flex-direction: column;
        align-items: initial;
      }
    }

    .custom-quantity {
      font-family: $fontText;
      margin-right: auto;
    }

    form {
      margin-left: auto;

      &.is-onsale.qty-sale {
        top: 0;
        transform: none;
        margin-top: 10px;
      }
    }

    .button.refresh {
      opacity: 0;
      outline: 0;
      padding: 5px;
      border: none;
      background: none;

      .icon-refresh {
        font-size: 16px;
        color: $colorAccent1;
      }
    }
  }

  .in-cart {
    //margin-right: 10px;

    input[type=number] {
      text-align: center;
      border: none;
      padding: 1px 1px 0 0;
      margin: 0 4px;
      font-size: 22px;
      font-weight: bold;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .btn-number {
      width: 25px;
      height: 25px;
      font-size: 16px;
      background-color: $white;
      font-family: $fontTitle1;
      font-weight: 500;
      outline: none;
      cursor: pointer;
      color: $white;
      border: 1px solid $colorAccent1;
      border-radius: 1px;

      background: #8bce58;
      background: -webkit-gradient(left top, right top, color-stop(0%, #8bce58), color-stop(34%, #75c857), color-stop(53%, #54be54), color-stop(100%, #43b953));
      background: -webkit-gradient(linear, left top, right top, from(#8bce58), color-stop(34%, #75c857), color-stop(53%, #54be54), to(#43b953));
      background: linear-gradient(to right, #8bce58 0%, #75c857 34%, #54be54 53%, #43b953 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bce58', endColorstr='#43b953', GradientType=1 );

      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: none;
        color: $colorAccent1;
      }
    }
  }

  .remove-from-cart {
    width: 20px;
    height: 20px;
    line-height: 15px;
    text-align: right;

    .button.remove {
      position: relative;
      padding: 0;
      margin-top: 4px;
      background: none;
      border: none;
      outline: none;

      i {
        font-size: 16px;
        color: #f1585d;
      }
    }
  }
}

.is-out-of-stock {
  margin: 0;
  font-family: $fontText;
  font-weight: 600;
  color: $colorAccent4;
  text-transform: capitalize;
}

.sidepanel-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(100%);
  z-index: -1;
  background: rgba(0,0,0,.0);

  transition: all 0.3s ease-in;

  .svg-loader {
    display: none;
    position: absolute;
    top: 25px;
    left: 25px;
  }

  &.opened {
    z-index: 99;
    background: rgba(0,0,0,.5);
    cursor: url(/img/site/cursor.png), auto;
  }

  &.is-loading {
    .svg-loader {
      display: block;
    }
  }
}

.shop .sidepanel-header {
  background-color: $newGrey;
}

.sticky-content {
  position: relative;
  top: 0;

  &.fixed {
    position: relative;
    top: 0;

    .refresh-zone {
      overflow: scroll;
      max-height: 800px;
    }
  }
}

.rentalsCrossSaleTickets {
  position: absolute;
  top: -5555px;
  left: -5555px;
  opacity: 0;
}
