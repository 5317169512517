// --------------------------------------------------
// Global typography behavior
// -------------------------------------------------
a {
  color: inherit;
  text-decoration: none;
}

/*
 * 		Heading Separator
 * 		-----------------
 */

.heading-separator {

  position: relative;
  margin: 20px 0 40px;

  &:before {

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 1px;
    background-color: #7E7E7E;
  }

  &.small {

    margin: 20px;

    &:before {

      width: calc(100% - 60px);
    }
  }

  &.medium {

    margin: 20px auto 40px;

    &:before {

      width: calc(100% - 60px);
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 10px 20px;
    transform: translate(-50%, -50%);
    background-color: $white;
    color: #57616E;
    font-size: 18px;
    line-height: 28px;
    font-family: $fontText;
    text-transform: lowercase;
    z-index: 1;
  }
}
