.account {
	margin: auto;

	.u-full-width {
		margin-bottom: 20px;
	}

  .birthday-wrapper {
    position: relative;

    .birthday-text {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: 0;
      padding: 12px 20px;
      letter-spacing: 0.05em;
      color: $dark;
      font-family: $fontText;
      font-size: 18px;
      line-height: 28px;
      outline: none;
    }

    input {
      text-indent: -9999px;
    }
  }

	.input-group-radio {
		justify-content: space-around;

		
		margin-bottom: 10px;



		.radio {
			align-items: center;

			float: left;

			width: 100%;
			height: 52px;

			padding: 12px 15px 0;

			border: 0;
			background-color: #f4f4f4;
			border-radius: 3px;

			letter-spacing: 0.05em;
			color: #5e5f61;
			font-size: 14px;
			font-weight: 400;
			outline: none;

			&:nth-child(n):not(:last-child) {
				margin-right: 10px;
			}

			&.is-checked {
				border: 1px solid $colorAccent1;
			}
		}

		.radio label {
			padding-bottom: 7.5px;
			padding-left: 0;
		}

		.radio input {
			height: 15px !important;
		}
	}

	.form-actions {

		.address-cta {
			flex-wrap: wrap;

			div {
				margin: 25px 15px;
			}
		}
	}

	@media (max-width: 768px) {
		.col {
			flex-direction: column;

			.left, .right {
				width: 100%;
			}

			.right {
				margin-left: 0;
			}
		}
	}

}

.profile-avatar {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 0px;
	margin-bottom: 50px;
	padding: 10px 20px 10px;

	.input-avatar {
		position: relative;
		display: inline-block;
		width: 140px;
		height: 140px;
		background-color: $light;
		border: 1px solid $colorAccent1;
		border-radius: 50%;
		color: $colorAccent1;
		font-size: 0.875em;
		font-weight: 500;
		text-decoration: none;
		text-align: center;
		overflow: hidden;
		cursor: pointer;

		transition: background 1s ease;


		input {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			width: 100%;
			margin: 0;
			padding: 0;
			font-size: 20px;
			cursor: pointer;
			opacity: 0;
			filter: alpha(opacity=0);
			z-index: 1;
		}

		.icon {
			position: absolute;
			top: 50%;
			left: 50%;

			transform: translate(-50%, -50%);

			font-size: 60px;

			opacity: 0;

			transition: opacity 1s ease;

			@media (max-width:767px) {
				font-size: 55px;
			}

		}

		&:hover {
			background-color: $dark;

			.icon {
				opacity: 1;
			}
		}

	}

}
