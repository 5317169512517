.shop.shop-form {

  .title {
    text-align: left;

    .title3 {
      margin: 0;
      font-family: $fontText;
      font-size: 40px;
      line-height: 48px;
      color: $colorAccent2;
      text-transform: uppercase;
    }
  }

  .notice-content-shop {
    max-width: 640px;
    margin: auto;
    h2 {
      //padding-left: 20px !important;
    }
  }

  &.order-confirmation {
    .title3 {
      text-align: center;
    }
  }

  .content {
    width: 1200px;
    max-width: 100%;
    margin: auto;

    @media (max-width: 991px) {
      width: 100%;
      flex-direction: column;
    }

    .main-form {
      flex-direction: column;
      width: calc(100% - 425px);

      @media (max-width: 1149px) {
        width: calc(100% - 350px);
      }

      @media (max-width: 991px) {
        width: 100%;
      }

      label.radio {
        display: block;
        padding-bottom: 15px;
        margin-top: -15px;

        &:first-child {
          margin-top: 0;
        }

        span {
          position: relative;
          top: 2px;
        }
      }
    }

    form {
      input,
      select,
      textarea {
        background-color: #f4f4f4;
      }

      .u-full-width {
      }

      .site-btn {

        @media (max-width: 599px) {
          font-size: 12px;
          width: 100%;
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      margin: 20px 0;
      background: $light;
    }

    .zone-shipping-only {
      margin-bottom: 50px;
    }

    .address-fieldset {
      .title5 {
        margin: 0 0 30px;
        font-size: 40px;
        line-height: 48px;
        text-transform: uppercase;
        font-family: $fontText;
        color: $colorAccent2;
      }

      label {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 30px;
        font-family: $fontTitle2;
        text-transform: uppercase;
      }
    }

    .button.accent {
      width: 100%;

      &:disabled {
        cursor: default;
        opacity: 0.5;
      }
    }

    .shipping-methods {
      margin-top: 50px;
    }

    .checkbox-select {
      width: 100%;
      margin-top: -5px;
    }

    .popp002 {
      margin-left: 25px;

      @media (max-width: 1149px) {
        width: 350px;
      }

      @media (max-width: 992px) {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
      }

      @media (max-width: 599px) {
        margin-top: 20px;
      }

      .sidepanel-close {
        display: none;
      }

      .sidepanel-footer-cta {
        display: none;
      }

      .line-item {

        @media (max-width: 599px) {
          flex-direction: column;
        }

        .main-text {
          font-size: 14px;
          margin-bottom: 10px;

          &.vertical-end {
            margin: 0;
            font-family: $fontTitle1;
            font-size: 18px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.4625px;
            color: $black;
          }

          &.price.is-onsale {
            display: flex;
            flex-direction: column;

            small {
              font-size: 12px;
              text-decoration: line-through;

              &.og-price {
                font-size: 9px;
              }

              &.coupon-sale {
                text-align: right;
                margin-top: 7px;
                text-decoration: none;
                color: $colorAccent4;

                span {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
}
