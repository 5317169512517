// --------------------------------------------------
// Skishop
// --------------------------------------------------

.skishop-container {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: stretch;
  padding-top: 60px;
  padding-bottom: 80px;
}

.skishop-sidepanel {
  width: 340px;
  padding: 60px 40px 0 30px;

  @media (max-width: 899px) {
    display: none;
  }

  .categories-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $light;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .is-expendable {
    position: relative;
    overflow: hidden;

    &.is-active {
      .categories-list {
        max-height: 100vh;
      }

      .categories-title {
        i {
          transform: translateY(-50%) rotate(0);
        }
      }
    }

    .categories-list {
      max-height: 0;
    }
  }

  .categories-title {
    position: relative;
    width: 100%;
    margin: 0;
    padding-left: 10px;
    padding-right: 20px;
    font-size: 20px;
    line-height: 19px;
    font-family: $fontTitle1;
    color: $colorAccent2;
    text-transform: uppercase;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      right: 0;
      transition: .4s all ease;
      transform: translateY(-50%) rotate(-90deg);
    }
  }

  .categories-list {
    width: 100%;
    transition: .8s all ease;

    .list-container {
      width: 100%;
      padding-top: 20px;
    }

    .list-item {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
      padding: 8px 10px 5px;
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: $colorAccent2;
      border-radius: 5px;
      transition: .4s all ease;
      cursor: pointer;

      input[type="radio"] {
        position: absolute;
        top: -55555px;
        left: -55555px;
        opacity: 0;
      }

      &.on-sales {
        color: $colorAccent1;
        font-weight: bold;
      }

      &:hover,
      &.is-active {
        background: #EBF3EA;
        font-weight: 500;

        &.on-sales {
          font-weight: bold;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.skishop-body {
  width: 100%;
  padding-right: 120px;

  @media(max-width: 1199px) {
    padding-right: 60px;
  }

  @media(max-width: 899px) {
    padding: 0 30px;
  }

  .body-breadcrumbs {
    position: relative;
    margin-bottom: 25px;

    span {
      font-family: $fontTitle2;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      color: $colorAccent2;
      text-transform: uppercase;

      &:first-of-type {
        color: #57616E;
      }

      @media (max-width: 899px) {
        font-size: 24px;
        line-height: 27px;
      }
    }

    a {
      display: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      line-height: 27px;

      @media (max-width: 899px) {
        display: inline-block;
        top: 0;
        transform: none;
      }
    }

    form {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 12px 68px 15px 15px ;
      background: #F2F2F2;
      border-radius: 5px;

      @media (max-width: 899px) {
        position: relative;
        top: auto;
        right: auto;
        transform: none;
        width: 100%;
        margin-top: 30px;
      }

      input {
        width: 100%;
        font-family: $fontText;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        //line-height: 28px;
        color: $black;
        background: transparent;
        border: none;

        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #A0AAB4;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          color: #A0AAB4;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          color: #A0AAB4;
        }

        &:focus {
          outline: 0;
        }
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        height: 48px;
        border: 1px solid $black;
        border-radius: 5px;
        background: $black;
        cursor: pointer;
        transition: all .4s ease;

        &:hover {
          background: $white;

          i {
            color: $black;
          }
        }

        &:focus {
          outline: 0;
        }

        i {
          color: $white;
          transition: all .4s ease;
        }
      }


      //font-size: 24px;
      //line-height: 27px;

      @media (max-width: 899px) {
        display: inline-block;
      }
    }
  }

  .body-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 25px 0 10px;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;

    @media (max-width: 899px) {
      display: none;
    }

    &:nth-child(3) {
      border-top: none;
    }

    .toolbar-sorting {
      display: flex;
      align-items: center;
      font-family: $fontText;
      font-size: 18px;
      line-height: 28px;
      color: #57616E;
      margin-bottom: 15px;

      .sorting-wrapper {
        position: relative;
        margin-left: 10px;
        border: 1px solid $colorAccent2;
        border-radius: 4px;

        i {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          color: $colorAccent2;
          font-size: 16px;
          transform: translateY(-50%);
        }
      }

      select {
        width: 100%;
        padding: 5px 10px;
        padding-right: 30px;
        border: none;
        font-size: 16px;
        line-height: 28px;
        color: $colorAccent2;
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }

    .toolbar-sales {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 15px;

      label {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 28px;
        font-family: $fontText;
        color: $colorAccent2;

        &:first-of-type {
          margin-right: 40px;
        }
      }

      input {
        margin-right: 8px;
      }
    }

    .toolbar-right {
      display: flex;
      align-items: center;
    }

    .toolbar-count {
      text-transform: uppercase;
      color: $colorAccent2;
      font-size: 18px;
      line-height: 30px;
      font-family: $fontText;
      margin-bottom: 15px;
    }

    .toolbar-limits {
      display: flex;
      align-items: center;
      font-family: $fontText;
      font-size: 18px;
      line-height: 28px;
      color: #57616E;

      form {
        margin-left: 15px;
      }

      input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;

        &:focus + label {
          border: 1px solid $colorAccent1;
        }

        &:checked + label {
          border-color: $colorAccent1;
        }
      }

      label {
        display: inline-block;
        background-color: $white;
        margin-right: 10px;
        padding: 5px 10px;
        font-family: $fontText;
        font-size: 16px;
        color: $black;
        font-weight: 500;
        border: 1px solid $black;
        border-radius: 4px;
        transition: .3s all ease;
        cursor: pointer;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .body-products {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 25px;
    text-align: center;

    @media (max-width: 649px) {
      flex-wrap: wrap;
    }

    .product-no-result {
      width: 100%;
      padding-top: 50px;
      text-transform: uppercase;
      color: $colorAccent2;
      font-size: 18px;
      line-height: 30px;
      font-family: $fontText;
      text-align: center;
    }
  }

  .products-item {
    display: flex;
    flex-direction: column;
    width: calc((100% / 3) - ( 80px / 3));
    margin-right: 40px;
    margin-bottom: 40px;
    padding-top: 10px;
    border: 1px solid $light;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    @media(max-width: 1199px) {
      width: calc((100% / 2) - (40px / 2));

      &:nth-child(3n + 3) {
        margin-right: 40px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media(max-width: 649px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }

    .item-header {
      position: relative;
      width: 80%;
      height: 200px;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .item-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .item-is-promo {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 15px 5px;
      font-family: $fontTitle1;
      font-size: 16px;
      color: $white;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      background: $colorAccent4;
    }

    .item-content {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px 40px;
      text-align: center;
    }

    .item-title {
      margin: 0 0 5px;
      font-family: $fontTitle1;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      text-transform: uppercase;
      color: $colorAccent2;
    }

    .item-brand {
      margin: 0 0 15px;
      font-family: $fontText;
      font-size: 16px;
      line-height: 22px;
      color: #7E7E7E;
    }

    .item-price {
      strong {
          font-family: $fontTitle1;
          font-size: 18px;
          line-height: 40px;
          color: #57616E;
      }

      small {
        margin-left: 5px;
        font-family: $fontCaption;
        font-size: 14px;
        line-height: 30px;
        text-decoration: line-through;
        color: #7E7E7E;
      }
    }

    .button-wrapper {
      margin-top: auto;
    }

    .button {
      @media(max-width: 899px) {
        font-size: 14px;
        line-height: 13px;
      }
    }
  }
}

.product-details-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 60px;

  @media(max-width: 1180px) {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .thumbnails-panel {
    position: relative;
    width: 185px;
    max-height: 600px;

    //&::before {
    //  content: '';
    //  position: absolute;
    //  top: -20px;
    //  left: 0;
    //  right: 0;
    //  z-index: 5;
    //  height: 100px;
    //  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
    //  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
    //  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    //  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
    //}
    //
    //&::after {
    //  content: '';
    //  position: absolute;
    //  bottom: -10px;
    //  left: 0;
    //  right: 0;
    //  z-index: 5;
    //  height: 100px;
    //  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    //  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
    //  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    //  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
    //}

    @media(max-width: 1180px) {
      order: 2;
      width: 100%;
      margin-top: 40px;

      //&::before {
      //  bottom: 0;
      //  top: 0;
      //  left: 0;
      //  right: auto;
      //  z-index: 5;
      //  width: 50px;
      //  height: 100%;
      //  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
      //  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
      //  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
      //  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      //  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
      //}
      //
      //&::after {
      //  bottom: 0;
      //  top: 0;
      //  right: 0;
      //  left: auto;
      //  z-index: 5;
      //  width: 50px;
      //  height: 100%;
      //  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      //  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
      //  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
      //  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      //  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
      //
      //}
    }

    .thumbnails-wrapper {
      position: relative;
      overflow-y: auto;
      max-height: 600px;
      padding-right: 20px;

      overflow: -moz-scrollbars-none; /* Firefox */
      -ms-overflow-style: none; /* IE */

      &::-webkit-scrollbar {
        width: 0;
      }

      @media (max-width: 1180px) {
        overflow-x: auto;
        display: flex;
      }
    }

    .thumbnail {
      display: block;
      width: 100%;
      margin-bottom: 20px;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media(max-width: 1180px) {
        height: 100%;
        max-width: 125px;
        margin-bottom: 0;
        margin-right: 10px
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .visual-panel-wrapper {
    width: 400px;
    //height: 400px;

    @media(max-width: 1180px) {
      order: 1;
      width: 100%;
      height: 100%;
      max-width: 400px;
      min-height: 400px;
      margin: 0 auto;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .details-panel {
    flex: 1;
    padding-left: 40px;

    @media(max-width: 1180px) {
      order: 3;
      max-width: 100%;
      margin-top: 25px;
      padding-left: 0;
    }

    .details-breadcrumbs {
      margin: 0 0 15px;
      font-family: $fontCaption;
      font-size: 14px;
      line-height: 13px;
      text-transform: uppercase;
      color: $dark;

      a {
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        color: $colorAccent1;
      }
    }

    .details-title {
      margin: 0 0 10px;
      font-size: 32px;
      line-height: 38px;
      text-transform: uppercase;
      color: $colorAccent2;
      font-family: $fontText;
    }

    .details-brand {
      margin: 0 0 25px;
      font-size: 18px;
      line-height: 28px;
      color: $dark;
      font-family: $fontText;
    }

    .details-description {
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 28px;
      color: #57616E;
      font-family: $fontText;
    }

    .details-price {
      margin: 15px 0 30px;
      font-size: 18px;
      line-height: 28px;
      color: #57616E;
      font-family: $fontTitle1;

      small,s {
        font-size: 80%;
        text-decoration: line-through;
      }
    }

    .details-choices {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .choices-label {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: $colorAccent2;
        font-family: $fontTitle2;
        text-transform: uppercase;
      }

      .choices-select-wrapper {
        position: relative;
        width: 100%;
        color: $colorAccent2;
        background: $light;

        i {
          position: absolute;
          right: 10px;
          top: 50%;
          font-size: 18px;
          transform: translateY(-50%);
        }
      }

      .choices-select {
        width: 100%;
        padding: 15px;
        font-family: $fontText;
        font-size: 18px;
        line-height: 28px;
        color: $colorAccent2;
        border: none;
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
          outline: 0;
        }
      }
    }

    .button {
      width: 100%;
      margin-top: 30px;
    }
  }
}

.skipshop-mobile-filters {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  display: none;
  height: 100vh;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 125px;
  background: $white;

  &.is-active {
    display: block;
  }

  .filters-close {
    position: absolute;
    right: 10px;
    top: 20px;
    font-size: 30px;
  }

  .filters-title {
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 27px;
    text-transform: uppercase;
    font-family: $fontText;
  }

  .filter-title {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: $fontTitle1;
  }

  .filters-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    border-bottom: 1px solid $light;

    &:last-of-type {
      border-bottom: none;
    }

    .list-item {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 28px;
      font-family: $fontText;

      &.on-sales {
        color: $colorAccent1;
        font-weight: bold;
      }

      &.is-active {
        font-family: $fontTitle2;

        &.on-sales {
          font-weight: bold;
        }
      }

      &:last-of-type {
        margin-bottom: 25px;
      }
    }
  }

  .filters-actions {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: stretch;
    padding: 20px;
    background: $white;

    @media(max-width: 325px) {
      flex-wrap: wrap;
    }

    .button {
      width: calc(50% - 10px);

      @media(max-width: 325px) {
        width: 100%;
      }

      &:first-child {
        margin-right: 20px;
        color: $colorAccent2;
        background: $white;

        @media(max-width: 325px) {
          margin-right: 0;
          margin-bottom: 10px;
        }

        &:hover {
          color: $white;
          background: $colorAccent2;
        }
      }
    }
  }
}
