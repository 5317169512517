// --------------------------------------------------
// Simple Grid system
// --------------------------------------------------

.site-padding {
  padding: 0 55px;

  @media (max-width: 999px) {
    padding: 0 25px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.site-wrapper {
  @extend .site-padding;

  width: 100%;

  &.max-width {
    max-width: 1024px;
    margin: 0 auto;
  }
}
