.signup-form {
  .flex {

  	div {
  		width: 45%;
  	}

    @media (max-width:425px) {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
}