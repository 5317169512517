.shop-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 50px;

  @media (max-width: 767px) {
    padding: 30px 25px;
  }

  @media (max-width: 499px) {
    padding: 20px 15px 40px;
  }

  .back-to {
    .button {
      padding: 15px;

      border: 1px solid #40B972;
      letter-spacing: 0.0585em;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      padding: 15px;
      box-shadow: 0 2px 4px rgba(159, 159, 159, .61);
      background: rgba(139, 206, 88, 1);
      background: -moz-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(139, 206, 88, 1)), color-stop(34%, rgba(117, 200, 87, 1)), color-stop(53%, rgba(84, 190, 84, 1)), color-stop(100%, rgba(67, 185, 83, 1)));
      background: -webkit-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: -o-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: -ms-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: linear-gradient(to right, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bce58', endColorstr='#43b953', GradientType=1);
    }
    i {
      position: relative;
      top: 1px;
      font-weight: 600;
    }
  }

  .shop-nav-btns {
    .button {
      padding: 15px;

      border: 1px solid #40B972;
      letter-spacing: 0.0585em;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      padding: 15px;
      box-shadow: 0 2px 4px rgba(159, 159, 159, .61);
      background: rgba(139, 206, 88, 1);
      background: -moz-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(139, 206, 88, 1)), color-stop(34%, rgba(117, 200, 87, 1)), color-stop(53%, rgba(84, 190, 84, 1)), color-stop(100%, rgba(67, 185, 83, 1)));
      background: -webkit-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: -o-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: -ms-linear-gradient(left, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      background: linear-gradient(to right, rgba(139, 206, 88, 1) 0%, rgba(117, 200, 87, 1) 34%, rgba(84, 190, 84, 1) 53%, rgba(67, 185, 83, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bce58', endColorstr='#43b953', GradientType=1);
    }
  }

  .link {
    font-family: $fontTitle1;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.05em;
    font-weight: 400;

    @media (max-width: 499px) {
      font-size: 12px;
    }
  }

  .logout {
    a {
      margin: 0 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
