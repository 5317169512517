// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  @media (max-width: 767px) {
    overflow-x: scroll;
    justify-content: flex-start;
  }

  a,
  div {
    display: block;
    height: 35px;
    font-size: 0.89em;
    padding: 10px 25px;
    background: $white;
    color: $colorAccent2;
    text-decoration: none;
    background: $white;

    &.content-pagination-prev {
      display: flex;
      border-right: 1px solid $colorAccent2;
      margin-right: 20px;

       &:before {
         content: '\e804';
         font-family: "fontello";
         display: block;
         transform: rotate(90deg);
       }

      &.fast-prev {
        &:after {
          content: '\e804';
          font-family: "fontello";
          display: block;
          transform: rotate(90deg);
        }
      }
    }
    &.content-pagination-next {
      display: flex;
      border-left: 1px solid $colorAccent2;
      margin-left: 20px;

       &:before {
         content: '\e805';
         font-family: "fontello";
         display: block;
         transform: rotate(90deg);
       }

      &.fast-next {
        &:after {
          content: '\e805';
          font-family: "fontello";
          display: block;
          transform: rotate(90deg);
        }
      }
    }
    &.content-pagination-prev,
    &.content-pagination-next {

      span {
        display: block;
        width: 0;
        height: 0;

      }
    }

    &:hover {
      color: $colorAccent2;
    }

    &.is-current {
      background: $colorAccent1;
      color: white;
      border-radius: 50%;
      padding: 10px 14px;
    }
  }
}
