// --------------------------------------------------
// Tickets index page - Tickets list
// --------------------------------------------------

.site-wrapper.for-ticket {
  padding: 0;

  @media (max-width: 665px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.forfaits-categories-wrapper {
  margin: auto;
  max-width: 1040px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  .categories-text {
    display: none;
    margin: 0;
    line-height: 1.5;
    font-size: 18px;
    color: #000;

    p {
      margin: 0 !important;
    }

    &.is-active {
      display: inline-block;
    }
  }
}

.tickets-categories,
.forfaits-categories {
  display: flex;
  justify-content: center;
  margin-bottom: 33px;

  @media (max-width: 665px) {
    margin-bottom: 32px;
  }

  .categories-item {
    margin-right: 30px;
    padding: 15px;
    border-radius: 25px;
    color: $black;
    font-family: $fontBtn;
    font-size: 14px;
    line-height: 13px;
    text-transform: uppercase;
    border: 1px solid $black;
    background: $white;
    transition: .4s all ease;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover,
    &.is-active {
      color: $white;
      background: $black
    }

    &.is-disabled {
      pointer-events: none;
      opacity: .3;
    }

    @media (max-width: 665px) {
      border-radius: 50px;
      margin-right: 9px;
      text-align: center;

      line-height: 15px;
      letter-spacing: 0.4625px;
    }
  }
}

.forfaits-categories {
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1024px;
  margin: 20px auto 0 auto;
  border-radius: 8px;
  line-height: 1.5;
  font-size: 18px;
  color: $grey;
}

.intro-alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: -45px;
  padding: 20px;
  border: 1px solid #aecdf3;
  border-radius: 8px;
  line-height: 1.5;
  font-size: 18px;
  color: $grey;
  background: $light;

  &.fix-margins {
    margin: -45px auto 45px auto;
  }

  svg {
    width: 30px;
    min-width: 30px;
    margin-right: 20px;
  }

  p {
    margin: 0;
    color: rgba(#57616e, .7);
  }

  a {
    color: rgba(#1f64bc, .7);
    text-decoration: underline;
  }

  .regular-text {
    @media (max-width: 655px) {
      font-size: 14px;

      br {
        display: none;
      }
    }
  }
}

.section-intro {
  @extend .site-padding;
  position: relative;
  z-index: 5;

  padding-top: 100px;
  text-align: left;
  background: transparent;
  border-bottom: 2px solid $grey;
  padding-bottom: 56px;

  @media (max-width: 665px) {
    padding-top: 52px;
    padding-bottom: 36px;
  }

  &.calendar-intro {
    padding-top: 0;
  }

  &#rentals-alert {
    padding-top: 0;
    padding-bottom: 125px;

    @media (max-width: 665px) {
      padding-top: 52px;
      padding-bottom: 36px;
    }
  }

  .intro-title {
    display: block;
    width: 100%;
    font-family: $fontTitle2;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;

    text-align: start;
    text-transform: uppercase;

    color: #030000;
    margin: 0 auto 56px;
    max-width: 1040px;

    @media (max-width: 665px) {
      font-size: 24px;
      line-height: 27px;
    }
  }

  .intro-text {
    //padding-bottom: 100px;
    max-width: 1040px;
    margin: 25px auto 0;
    //margin-bottom: 75px;
    line-height: 1.5;
    font-size: 18px;
    color: $black;

    &.location-intro-text {
      text-align: center;
      margin-bottom: 40px;
    }

    @media (max-width: 665px) {
      font-size: 16px;
    }

    a {
      text-decoration: underline;
    }
  }
}
.pagination {
  position: relative;
  width: 100%;
  background: $light;
  padding: 55px;
}

.tickets-container {
  position: relative;
  z-index: 5;
  width: 100%;
  background: transparent;
  padding: 55px 55px 400px;
  padding-top: 0;

  @media (max-width: 767px) {
    padding: 55px 20px 250px;
    padding-top: 0;
  }

  .datepicker {
    position: relative;
    margin: 0 auto;
    display: flex;
    border: 2px solid $colorAccent1;
    border-radius: 90px;
    width: 435px;
    padding: 0 0 0 0;
    background: #fff;
    cursor: pointer;
    line-height: 1;
    margin-bottom: 49px;

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 34px;
    }

    .datepicker-delegate {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background: transparent;
    }

    .datepicker-label {
      position: absolute;
      top: -25px;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translate3d(-50%, -100%, 0);
      color: $dark;
      font-size: 18px;
      font-weight: 600;

      @media (max-width: 665px) {
       top: 0;
       padding-bottom: 25px;
      }
    }

    input {
      box-sizing: border-box;
      width: 100%;
      height: 65px;
      margin: 0;
      padding: 0 20px 0 30px;
      color: #000;
      font-family: $fontTitle3;
      font-size: 18px;
      line-height: 30px;
      text-transform: uppercase;
      border: none;
      border-radius: 90px;
      outline: none;
      cursor: pointer;

      &::placeholder {
        color: #000;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .icon-calendar-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: 65px;
      line-height: 65px;
      text-align: center;
      padding: 20px;
      font-size: 20px;
      //border-left: 2px solid rgba(0, 0, 0, 0.08);
      color: $colorAccent2;
      pointer-events: none;

      .icon-calendar-new {
        font-size: 28px;
      }
    }
  }
}

.tickets-date-row {
  margin-bottom: 75px;

  .tickets-date-text {
    color: $dark;
    text-align: center;
    font-weight: 400;
  }
}

.tickets-list-wrapper {
  padding: 0;
  margin: 0;
}

.tickets-remaining,
.rentals-remaining {
  display: none;
  width: 100%;
  font-family: $fontBtn;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;

  text-align: center;
  text-transform: uppercase;

  &.is-active {
    display: block;
  }

  /* Green */

  color: #000;
  margin-top: 0;
  margin-bottom: 24px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 18px;
  }

  &.is-morning {
      color: #797ab9;
  }

  &.is-day {
    color: #64BD29;
  }

  &.is-night {
    color: #002D60;
  }

  span {
    color: #000;
  }
}

.tickets-list {
  display: none;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &.is-active {
    display: flex;
  }

  .shop-status {
    margin: 0;
    padding: 10px 20px;
    border-left: 4px solid $colorAccent4;
    border-radius: 4px 0 0 4px;
    background: rgba($colorAccent4, .2);
    color: $dark;

  }

  .ticket {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc((100% / 3) - (42px / 3));
    margin-bottom: 20px;
    background-color: #000;
    margin-right: 21px;
    box-shadow: 0px 2px 30px rgba(159, 159, 159, 0.24);

    &:nth-child(1n) {
      .ticket-header {
        background-color: #FF5152;

        .ticket-header-reduction {
          color: #FF5152;
        }
      }
    }

    &:nth-child(2n) {
      .ticket-header {
        background-color: #62D2DD;

        .ticket-header-reduction {
          color: #62D2DD;
        }
      }
    }

    &:nth-child(3n) {
      margin-right: 0;

      .ticket-header {
        background-color: #7171B4;

        .ticket-header-reduction {
          color: #7171B4;
        }
      }
    }

    &:nth-child(4n) {
      .ticket-header {
        background-color: #B53C49;

        .ticket-header-reduction {
          color: #B53C49;
        }
      }
    }

    @media(max-width: 1151px) {
      width: calc((100% / 2) - (21px / 2));

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 21px;
      }
    }

    @media(max-width: 767px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    &.loading {
      opacity: 0;
      left: -155px;
    }

    .ticket-header {
      background-color: #000;
      padding: 40px 20px 40px;
      position: relative;

      .ticket-header-reduction {
        position: absolute;
        top: 0;
        left: 0;
        font-family: $fontTitle2;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;

        text-align: center;
        letter-spacing: 0.2625px;
        text-transform: uppercase;

        color: #000;
        background-color: #fff;
        margin: 0;
        padding: 15px 5px;
      }

      .ticket-header-title {
        font-family: $fontTitle2;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 137% */

        text-align: center;
        letter-spacing: -0.233333px;

        color: #FFFFFF;
        margin-top: 0;
        margin-bottom: 8px;
      }

      .ticket-header-price {
        font-family: $fontBtn;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;

        text-align: center;
        text-transform: uppercase;

        color: #FFFFFF;
        margin-top: 0;
        margin-bottom: 0;

        span {
          display: inline-block;
          font-family: $fontTitle2;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;

          text-align: center;
          letter-spacing: -0.233333px;

          color: #FFFFFF;
        }
      }

      .ticket-header-initial {
        font-family: $fontBtn;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        /* identical to box height, or 214% */

        text-align: center;
        text-decoration-line: line-through;
        text-transform: uppercase;

        color: #FFFFFF;
        margin-top: 0;
        margin-bottom: 0;

        &.none {
          text-decoration-line: none;
        }
      }
    }

    .ticket-infos {
      background-color: #000;
      width: 100%;
      margin: 0;
      padding-bottom: 32px;

      .ticket-title {
        display: block;
        width: 100%;
        font-family: $fontBtn;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;

        text-align: center;
        text-transform: uppercase;

        color: #FFFFFF;
        margin-top: 0;
        margin-bottom: 16px;
        padding: 0;
      }

      .ticket-subtitle {
        display: block;
        width: 100%;
        font-family: $fontCaption;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 13px;

        text-align: center;
        letter-spacing: 0.2625px;

        color: #FFFFFF;
        margin: 0;

        &.skigo-notice {
          display: none;
          color: $colorAccent4;
          margin-top: 5px;
        }
      }
      //@media (max-width: 665px) {
      //  width: 100%;
      //  margin: 0 0;
      //  border-bottom: 2px solid rgba(0, 0, 0, .06);
      //  text-align: center;
      //
      //  .ticket-subtitle {
      //    width: 100%;
      //    margin: 0 0;
      //    text-align: center;
      //  }
      //}
    }

    //@media (max-width: 665px) {
    //  // flex-direction: column;
    //  max-width: 325px;
    //  margin: 0 auto 25px auto;
    //  flex-wrap: wrap;
    //  padding: 0;
    //  // padding-bottom: 25px;
    //}
  }

  h3, h4 {
    margin: 0;
    padding: 0;
  }

  .ticket-infos {
    padding: 25px;
    margin-right: auto;
    color: $dark;
    font-size: 18px;

    //@media (max-width: 798px) {
    //  font-size: 15px;
    //}
  }

  .ticket-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 90px;
    top: 0px;
    padding: 0;
    color: #DDDDDD;
    line-height: 1;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background-color: $grey;

    //@media (max-width: 665px) {
    //  width: 100%;
    //  border-bottom-left-radius: 0;
    //  border-top-right-radius: 5px;
    //  padding: 20px;
    //}

    img {
      width: 45px;
      margin: 0 auto;
      display: block;
    }
  }

  .ticket-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-right: auto;

    .ticket-title {
      padding-bottom: 10px;

      //@media (max-width: 665px) {
      //  width: 100%;
      //  margin: 0 0;
      //  text-align: center;
      //}
    }
  }

  .ticket-infos-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    color: $dark;

    //@media (max-width: 798px) {
    //  padding: 10px;
    //}
    //
    //@media (max-width: 665px) {
    //  width: 33.3333%;
    //  text-align: center;
    //  align-items: center;
    //}

    .ticket-col-title {
      width: 100%;
      text-align: center;
      margin-top: 0;
      margin-bottom: 15px;
      font-weight: 100;
    }

    .ticket-col-price {
      font-size: 34px;
      font-weight: 400;

      sup {
        display: inline-block;
        position: relative;
        top: -15px;
        font-size: 20px;
        font-weight: 200;
      }

      //@media (max-width: 798px) {
      //  font-size: 26px;
      //
      //  sup {
      //    font-size: 16px;
      //  }
      //}
    }

    &.ticket-price {

      border-left: 2px solid rgba(0, 0, 0, .05);
      border-right: 2px solid rgba(0, 0, 0, .05);
    }

    &.ticket-rebate {
      padding-left: 25px;
      padding-right: 25px;
      border-right: 2px solid rgba(0, 0, 0, .05);

      //@media (max-width: 798px) {
      //  padding-left: 10px;
      //  padding-right: 10px;
      //}
    }

    &.ticket-total {
      background: $dark;
      color: #fff;

      span {
        font-weight: 600;
      }
    }
  }

  .addToCartForm {

    .ticket-type-selector {
      -webkit-appearance: menulist;
      display: block;
      position: relative;
      width: calc(100% - (38px * 2));
      margin-left: 38px;
      margin-right: 38px;
      margin-bottom: 33px;
      background-color:transparent;
      border: none;

      font-family: $fontTitle2;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      /* identical to box height, or 167% */

      text-transform: uppercase;

      color: #FFFFFF;

      border-bottom: 2px solid #979797;
      padding-bottom: 6px;

      option {
        color: black;
      }
    }

    .form-bottom {
      position: relative;
      width: calc(100% - 20px);
      display: flex;

      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 16px;

      .off-limit-tickets {
        display: block;
        width: 100%;
        background-color: #fff;
        color: #000;

        font-family: $fontBtn;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;

        text-align: center;
        letter-spacing: 0.4625px;
        text-transform: uppercase;
        padding: 8px;
        max-width: 160px;
        margin: 0 auto;
        border-radius: 75px;
      }

      .input-number {
        position: relative;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 50px;
        max-width: 75px;
        border: none;
        text-align: center;
        padding: 10px 5px;
        font-weight: 600;
        outline: none;
        font-size: 20px;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          opacity: 1;
        }
      }

      .control-button {
        width: 100%;
        height: 100%;
        min-height: 50px;
        padding: 0 20px;
        background: #fff;
        border: none;
        background: $colorAccent1;
        font-size: 16px;
        color: #fff;
        outline: none;
        border-radius: 0;
        border: none;
        margin: 0;

        &:hover {
          opacity: .65;
          cursor: pointer;
        }

        &[data-type="minus"] {
          border-top: 2px solid rgba(0, 0, 0, .05);
          border-bottom: none;
        }
      }
    }

  }


}

.tickets-cta-wrapper {
  display: none;
  width: 100%;
  padding: 25px 20px 0 20px;
  text-align: center;
}

.ticket-details {
  .refresh-zone {
    .sidepanel-header {
      margin-bottom: 0;
      border-bottom: 0;

      .sidepanel-close {
        display: none;
      }
    }
    .sidepanel-content {
      padding-top: 0;
    }
  }
}





.follow-datepicker-wrapper {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, .08);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .08);
  padding: 20px;

  transition: all 0.35s ease;

  z-index: -1;

  .logo {
    display: inline-block;
    width: 145px;
    height: auto;
  }

  .button {
    margin-left: auto;
  }

  .group-title {
    font-size: 18px;
    text-align: right;
    color: $grey;
    padding-left: 20px;

    @media (max-width: 599px) {
      font-size: 12px;

      span {
        display: none;
      }
    }

    strong {
      color: $colorAccent1;
      font-weight: 800;
    }
  }

  &.is-active {
    top: 0;
    z-index: 9999;
  }

  @media (max-width: 665px) {
    .button {
      span {
        display: none;
      }
    }
  }
}


.calendar-container {
  position: relative;
  z-index: 5;
  width: 100%;
  background: white;
  padding: 55px 55px 400px;

  @media (max-width: 1165px) {
    padding: 55px 55px 400px;
  }

  @media (max-width: 767px) {
    padding: 55px 20px 250px;
  }

  .calendar-wrapper {
    position: relative;
    max-width: 1040px;
    margin: 30px auto 0;
  }

  .calendar-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  .prev-month,
  .next-month {
    display: flex;
    align-items: center;

    font-family: $fontTitle2;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #030000;

    @media (max-width: 1151px) {
      span {
        display: none;
      }
    }
  }

  .prev-month {
    margin-right: auto;

    &.not-active {
      opacity: 0.5;
      pointer-events: none;
      text-decoration: none;
    }

    svg {
      margin-right: 10.5px;
    }
  }

  .next-month {
    margin-left: auto;

    &.not-active {
      opacity: 0.5;
      pointer-events: none;
      text-decoration: none;
    }

    svg {
      margin-left: 10.5px;
    }
  }

  .calendar-title {
    font-family: $fontTitle2;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    max-width: 1040px;
    margin: 0 auto 20px;
    text-transform: uppercase;

    color: #030000;
  }

  .calendar-text {
    font-family: "pf_square_sans_proregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    max-width: 1040px;
    margin: 0 auto 20px;
    text-transform: uppercase;
    color: #7E7E7E;
  }

  .calendar-month {

    .month-name {
      font-family: $fontBtn;
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;

      text-align: center;
      text-transform: uppercase;

      /* Body */

      color: #030000;
      margin: 0;
    }
  }

  .calendar-days-names {
    display: flex;
    margin-bottom: 8px;

    @media (max-width: 1151px) {
      display: none;
    }

    .day-name {
      min-width: 140px;
      margin-right: 10px;

      font-family: $fontBtn;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      /* identical to box height, or 187% */

      text-align: center;
      text-transform: uppercase;

      /* Body */

      color: #030000;
      margin-top: 0;
      margin-bottom: 0;

      &:nth-child(7n) {
        margin-right: 0;
      }
    }
  }

  .calendar-days-wrapper {
    display: flex;
    flex-wrap: wrap;

    .calendar-day {
      position: relative;
      width: 140px;
      height: 140px;
      background: #F2F2F2;
      border: 1px solid #F2F2F2;
      margin-right: 10px;
      margin-bottom: 10px;

      @media (max-width: 1151px) {
        display: none;
        width: 100%;
        height: 70px;
        margin-right: 0;
        margin-bottom: 8px;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &.is-active {
        background: #FFFFFF;
        border: 2px solid #64BD29;

        svg {
          display: none;
        }

        @media (max-width: 1151px) {
          display: flex;

          svg {
            display: block;
          }

          .day-status {
            display: none;
          }
        }
      }

      &.is-past {
        cursor: none;
        pointer-events: none;
        background: #F2F2F2;
        border: 1px solid #F2F2F2;

        .day-status {
          display: none;
        }

        .day-tickets {
          display: none;
        }


        @media (max-width: 1151px) {
          display: none;
        }
      }

      &.is-complete {
        background: rgba(100, 189, 41, 0.1);
        border: 2px solid rgba(100, 189, 41, 0.1);

        @media (max-width: 1151px) {
          display: flex;
        }

        .day-status {
          font-size: 16px;
          line-height: 28px;

          @media (max-width: 1151px) {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }

      &.is-close {
        background: rgba(255, 81, 82, 0.1);
        border: 2px solid rgba(255, 81, 82, 0.1);

        @media (max-width: 1151px) {
          display: flex;
        }

        .day-status {
          font-size: 16px;
          line-height: 28px;

          color: #FF5152;

          @media (max-width: 1151px) {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }

      &:nth-child(7n) {
        margin-right: 0;
      }

      .day-number {
        position: absolute;
        top: 16px;
        right: 16px;
        font-family: $fontTitle2;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: #030000;
        margin: 0;

        span {
          display: none;
        }

        @media (max-width: 1151px) {
          position: relative;
          top: auto;
          right: auto;
          min-width: 90px;

          span {
            display: inline-block;
          }
        }
      }

      .day-status {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);

        font-family: $fontTitle2;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        white-space: nowrap;
        /* Body */

        color: #030000;

        @media (max-width: 1151px) {
          position: relative;
          top: auto;
          left: auto;
          transform: none;
        }
      }

      .day-tickets {
        display: flex;

        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%,0);
        width: 100%;

        @media (max-width: 1151px) {
          position: relative;
          bottom: auto;
          left: auto;
          transform: none;
          flex-direction: column;
        }

        > div {
          @media (min-width: 1082px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 3px;
          }
        }

        .ticket-evening,
        .ticket-morning {
          //padding-right: 12px;
          border-right: 1px solid #C4C4C4;

          @media (max-width: 1151px) {
            border-right: none;
            padding: 0;
          }
        }

        .ticket-title {
          font-family: $fontTitle2;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 14px;
          margin: 0;
          color: #030000;

          @media (max-width: 1151px) {
            display: inline-block;
            line-height: 1;

            &:after {
              content: ':';
            }
          }
        }

        .ticket-remaining {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          color: #64BD29;
          margin: 0;

          span {
            display: none;
          }

          @media (max-width: 1151px) {
            display: inline-block;

            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 1;

            span {
              display: inline-block;
            }
          }
        }


        .ticket-morning {
          .ticket-remaining {
            color: #797ab9;
          }
        }

        .ticket-night {
          //padding-left: 12px;

          @media (max-width: 1151px) {
            padding: 0;
          }

          .ticket-remaining {
            color: #002D60;
          }
        }
      }
    }
  }

  .calendar-days-wrapper-new-ui {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 1151px) {
      gap: 8px;
    }

    .calendar-day {
      position: relative;
      width: 140px;
      height: 140px;
      background: #F2F2F2;
      border: 1px solid #F2F2F2;

      @media (max-width: 1151px) {
        display: none;
        width: 100%;
        height: 70px;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      &.is-active {
        background: #FFFFFF;
        border: 2px solid var(--Body, #57616E);
        transition: all 0.35s ease;

        &:hover {
          border: 2px solid var(--Body, #030000);
          background: var(--Body, #030000);

          .day-number, .day-status .pricing-value, .day-status .pricing-label {
            color: #fff;
          }
        }

        svg {
          display: none;
        }

        @media (max-width: 1151px) {
          display: flex;

          svg {
            display: block;

            path {
              fill: var(--Body, #030000);
            }
          }
        }

        &.is-discounted {
          border: 2px solid #64BD29;

          &:hover {
            border: 2px solid #64BD29;
            background: #64BD29;

            .day-number, .day-status .pricing-value, .day-status .pricing-label {
              color: #030000;
            }

            .status-small-promo, .status-large-promo {
              background: rgba(255, 255, 255, 0.40);
            }
          }
        }

        &.is-sunday10 {
          border: 2px solid #002D60;
          background-color: #002D60;

          &:hover {
            border-color: #4EBFD6;
            background-color: #4EBFD6;

            .day-number {
              color: #002D60;
            }

            .sunday10-content {
              .sunday10-heading, .sunday10-text {
                color: #002D60;
              }
            }
          }

          .day-number {
            color: #fff;
          }

          .sunday10-content {
            position: absolute;
            bottom: 20px;
            left: 10px;
            right: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 3px;

            @media (max-width: 1151px) {
              position: relative;
              bottom: initial;
              left: initial;
              right: initial;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }

            .sunday10-heading {
              color: #4EBFD6;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: $fontTitle2;
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 93%;
              text-transform: uppercase;
              margin: 0;

              @media (max-width: 1151px) {
                font-size: 20px;
                margin-right: 20px;
              }

              @media (max-width: 424px) {
                font-size: 16px;
                margin-right: 8px;
              }
            }

            .sunday10-text {
              color: #FFF;
              font-feature-settings: 'clig' off, 'liga' off;
              font-family: 'pf_square_sans_prolight';
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 1;
              margin: 0;

              @media (max-width: 1151px) {
                min-width: 50px;
                display: flex;
                flex-direction: column;
                margin-right: 14px;
                font-size: 10px;
              }

              strong {
                font-family: 'pf_square_sans_probold';

                @media (max-width: 1151px) {
                  margin-top: 3px;
                  font-size: 16px;
                }
              }
            }

            svg path {
              fill: #fff;
            }
          }
        }
      }

      &.is-past {
        cursor: none;
        pointer-events: none;
        background: #F2F2F2;
        border: 1px solid #F2F2F2;

        .day-status {
          display: none;
        }

        .day-tickets {
          display: none;
        }


        @media (max-width: 1151px) {
          display: none;
        }
      }

      &.is-complete {
        @media (max-width: 1151px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .day-status {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 28px;
        }
      }

      &.is-close {
        background: rgba(255, 81, 82, 0.1);

        @media (max-width: 1151px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .day-status {display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          line-height: 28px;
          color: #FF5152;
        }
      }

      &:nth-child(7n) {
        margin-right: 0;
      }

      .day-number {
        position: absolute;
        top: 8px;
        right: 8px;
        font-family: $fontTitle2;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
        text-transform: uppercase;
        color: #030000;
        margin: 0;

        span {
          display: none;
        }

        @media (max-width: 1151px) {
          position: relative;
          top: auto;
          right: auto;
          min-width: 90px;
          font-family: $fontTitle1;

          span {
            display: inline-block;
          }
        }
      }

      .day-status {
        position: absolute;
        inset: 0;

        @media (max-width: 1151px) {
          position: relative;
          display: flex;
          align-items: center;
          gap: 16px;
        }

        .status-pricing {
          position: absolute;
          top: 50%;
          left: 16px;
          right: 16px;
          margin: 0;
          transform: translateY(-50%);
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 1151px) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            transform: none;
          }

          .pricing-label {
            font-family: $fontTitle3;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: normal;
            white-space: nowrap;
            color: #030000;
          }

          .pricing-value {
            font-family: 'pf_square_sans_probold';
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            color: #030000;
          }
        }

        .status-small-promo {
          display: none;
          margin: 0;
          max-width: calc(100% - 32px);
          border-radius: 17px;
          background: rgba(#64BD29, 0.4);
          padding: 6px 8px;
          font-family: $fontTitle2;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          white-space: nowrap;

          @media (max-width: 1151px) {
            display: flex;
          }
        }

        .status-large-promo {
          position: absolute;
          left: 50%;
          bottom: 16px;
          transform: translateX(-50%);
          display: flex;
          margin: 0;
          max-width: calc(100% - 32px);
          border-radius: 17px;
          background: rgba(#64BD29, 0.4);
          padding: 6px 8px;
          font-family: $fontTitle2;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          white-space: nowrap;

          @media (max-width: 1151px) {
            display: none;
          }
        }
      }
    }
  }
}


.is-blank {
  opacity: 0;
}
