// --------------------------------------------------
// Hero
// --------------------------------------------------

.hero {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 20vw;
  min-height: 540px;
  padding-left: 50px;
  padding-right: 50px;

  background-position: center;
  background-size: cover;
  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (max-width: 665px) {
    height: 360px;
    min-height: inherit;
  }

  //&:before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //  // background-color: rgba($colorAccent1, 0.75);
  //  background: '';
  //  z-index: 1;
  //}

  .hero-content {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    text-align: left;
  }

  h1 {
    position: relative;
    color: $white;
    padding-top: 15px;
    margin: 0;
    font-size: 72px;
    text-transform: uppercase;
    line-height: 72px;
    font-family: $fontTitle1;

    @media (max-width: 667px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  .hero-text {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin-top: 25px;
    font-family: $fontText;
    color: $white;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;

    @media (max-width: 667px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.breadcrumb-nav {
  position: relative;
  width: 100%;
  color: #fff;
  font-family: $fontTitle1;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.015;
  line-height: 1;

  z-index: 2;
}

.cart-alert {
  position: relative;
  width: 80%;
  margin: 20px auto;
  padding: 6px 20px;
  color: red;
  font-family: $fontTitle2;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.015;
  line-height: 1;
  background: rgba($colorAccent4, .25);
  border-radius: 4px;
}

.cart-success {
  position: relative;
  width: 80%;
  margin: 20px auto;
  padding: 6px 20px;
  color: $colorAccent1;
  font-family: $fontTitle2;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.015;
  line-height: 1;
  background: rgba($colorAccent1, .25);
  border-radius: 4px;
}
