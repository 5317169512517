// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $fontText;
  font-weight: 400;
  font-size: 16px;
}

// Helpers
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

select option {
  color: black;
}

hr {
  width: 100%;
  margin: 20px 0;
  border: none;
  height: 2px;
  background: rgba(0, 0, 0, .12);
}

.btn-social { border: 0; padding: 10px; font-weight: bold; }
.btn-social:not(.btn-primary) { background: rgba(0,0,0,0.05); text-align: left; }

.btn-social:not(.btn-primary):hover { background: rgba(0,0,0, 0.1); }
.btn-social:not(.btn-primary) img { width: 20px; margin-left: 4px; margin-right: 7px; border-radius: 3px; }

.img-social { width: 64px; padding: 7px; border-radius: 10px; }

.notifications { position: relative; text-align: center; margin-top: -20px; }
.notifications .notification { text-align: center; background: #999; display: inline-block; padding: 7px 14px; color: #fff; }
.notifications .notification.notice { background-color: #5286d9; }
.notifications .notification.error { background-color: #da5a47; }



/* Loader 2 */
.loader-container {
  position: relative;
  width: 100%;
}

.loader {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: 0px auto;
  width: 200px;
}
.loader:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

main {
  position: relative;
}

.bottom-background {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 800px;
  background: url('/site/img/ssb-background-bottom.png') no-repeat center / cover;
}

.custom-git-branch {
  position: fixed;
  display: flex;
  align-items: center;
  left: 20px;
  bottom: 20px;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: $colorAccent1;
  color: #fff;
  z-index: 999999;
  pointer-events: none;

  & > svg {
    width: auto;
    margin-right: 5px;
  }
}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #E1E1E1;
  }
  40% {
    stroke: #CCCCCC;
  }
  66% {
    stroke: #AEAEAE;
  }
  80%, 90% {
    stroke: #CCCCCC;
  }
}
