// --------------------------------------------------
// Footer
// --------------------------------------------------

.footer {
  //@extend .site-padding;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 100px 70px 65px 70px;
  border-top: 1px solid $grey;
  background: #030000;

  .footer-logo {
    img {
      width: 400px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .footer-address {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, .5);
  }

  .footer-socials {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 55px;

    .socials-link {
      position: relative;
      width: 36px;
      height: 36px;
      margin-right: 10px;
      border-radius: 50%;
      background: $colorAccent1;

      &:last-of-type {
        margin-right: 0;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 16px;
        color: $white;
        transform: translate(-50%, -50%);
      }
    }
  }

  .footer-terms {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, .5);

    a:first-of-type {
      margin-right:10px;
    }
  }

  .footer-rights {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(255, 255, 255, .25);
  }
}
