.terms-wrapper {
  padding: 80px 20px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .terms-content {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  p {
    margin: 0 0 40px;
    font-weight: 300;
    color: #73879A;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 40px;
  }

  h2 {
    @extend h4;
    //@extend .bold;
  }

  ul {
    color: #73879A;
  //  margin: 0 0 40px;
  //  padding: 0;
  //  list-style: none;
  //
  //  li {
  //    position: relative;
  //    padding-left: 30px;
  //
  //    &:before {
  //      position: absolute;
  //      content: '';
  //      left: 0;
  //      top: 8px;
  //      width: 11px;
  //      height: 11px;
  //      border-radius: 11px;
  //      border: 2px solid $colorAccent1;
  //    }
  //  }
  }

  img {
    margin: 0 auto 40px;
    max-width: 100%;
    height: 100%;
  }

  a {
    text-decoration: underline;
    color: $colorAccent1;
  }
}
