// --------------------------------------------------
// Header
// --------------------------------------------------

.header {
  background: $colorAccent2;
}

.top-bar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 25px;
  border-bottom: 1px solid rgba(#000, 0.08);

  a {
    display: inline-block;
    color: $white;
    text-transform: uppercase;
    padding-right: 25px;
    font-size: 14px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.2625px;
    font-family: $fontCaption;

    transition: opacity 0.35s ease;

    &:hover {
      opacity: .80;
    }
  }

  .top-bar-left-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 725px;
    overflow: hidden;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .top-bar-left-nav .link-text {
    position: relative;
    display: inline-block;
    padding: 34px 20px 46px;
    font-weight: 500;
    font-size: 16px;
    z-index: 2;
  }

  .top-bar-left-nav .link-text p {
    position: relative;
    margin: 0;
    z-index: 2;
  }

  .top-bar-left-nav .link-text:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -96px;
    display: block;
    height: 100%;
    width: 100%;
    background: $white;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  .top-bar-left-nav .link-text:hover:after, .top-bar-left-nav .link-text.is-active:after {
    bottom: 0;
  }

  .top-bar-right-nav {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: auto;

    a {
      display: inline-block;
      color: $white;
      text-transform: uppercase;
      padding-right: 25px;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.2625px;
      font-family: $fontCaption;

      transition: opacity 0.35s ease;

      &:hover {
        opacity: .80;
      }

      &:last-of-type {
        padding-right: 0;
      }

      @media (max-width:999px) {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
}

.mobile-trigger {
  margin: auto 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    span {
      &:last-of-type {
        width: 35px;
      }
    }
  }

  i {
    color: $colorAccent1;
    font-size: 20px;
  }

  span {
    display: block;
    width: 35px;
    height: 4px;
    margin-bottom: 5px;
    background-color: $black;
    transition: .4s all ease;

    &:last-of-type {
      width: 17.5px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba($colorAccent1, 1);
  z-index: -1;
  transition: all .3s ease-in-out;
}

@media (min-width: 1024px) {
  .mobile-nav {
    display: none;
  }

}

.mobile-nav.is-active {
  opacity: 1;
  z-index:9999;
}

.mobile-nav .links-holder {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  a {
    padding-right: 0;
    text-transform: uppercase;
  }

  .link-separator {
    width: 100%;
    max-width: 120px;
    height: 1px;
    margin-bottom: 40px;
    background: $white;
  }
}

.mobile-nav .link-text {
  margin-bottom: 40px;
  font-family: $fontTitle1;
  color: #FFF;
  font-size: 14px;
  line-height: 13px;
}

.mobile-nav .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  color: #FFF;
  font-size: 36px;
}

.main-nav {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  z-index: 2;
  background: $white;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.07), 0 0 29.2013px rgba(0, 0, 0, 0.0482987), 0 0 14.1767px rgba(0, 0, 0, 0.0389404), 0 0 6.94968px rgba(0, 0, 0, 0.0310596), 0 0 2.74791px rgba(0, 0, 0, 0.0217013);

  .main-nav-container {
    flex: 1;
    display: flex;
    padding: 20px 25px;

    @media (max-width: 1023px) {
      padding: 20px;
    }
  }

  .logo {
    display: block;
    height: 80px;
    width: auto;

    @media (max-width: 1023px) {
      height: 60px;
    }

    @media (max-width: 767px) {
      height: 40px;
    }
  }

  .left-nav {
    @media (max-width: 1023px) {
      display: flex;
      align-items: center;

      .logo {
        display: none;
      }
    }
  }

  .center-nav {
    display: flex;
    align-items: stretch;
    margin: 0 auto;

    .link {
      display: inline-block;
      padding: 0 25px;
      text-transform: uppercase;
      font-size: 16px;
      color: $colorAccent2;
      font-family: $fontTitle1;
      letter-spacing: -0.005em;
      line-height: 80px;

      transition: opacity 0.35s ease;

      &:hover {
        opacity: .80;
      }

      &:last-child {
        padding-right: 0;
      }

      &.is-active {
        position: relative;
        color: $colorAccent1;

        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: -20px;
          left: 0;
          height: 4px;
          background-color: $colorAccent1;
          z-index: 1;
        }
      }

      i {
        font-size: 20px;
      }
    }

    .logo {
      display: none;
    }

    @media (max-width: 1023px) {
      .link {
        display: none;
      }

      .logo {
        display: block;
      }
    }
  }

  .right-nav {
    display: flex;
    align-items: center;
    padding: 0 25px;
    background: $colorAccent1;

    a {
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 20px;
      padding: 5px 11px;
      border-radius: 50%;
      font-style: normal;
      font-family: $fontTitle1;
      font-size: 18px;
      background: $white;

      @media(max-width: 1023px) {
        margin-left: 10px;
        font-size: 16px;
      }
    }

    i {
      font-size: 24px;
      color: $white;
    }
  }
}

.main-nav-mobile {
  position: relative;
  display: flex;
  align-items: stretch;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  background: $white;

  @media(min-width: 1024px) {
    display: none;
  }

  .main-nav-container {
    flex: 1;
    display: flex;
    padding: 30px 20px;
  }

  .center-nav {
    position: relative;
    margin: 0 auto;

    .link {
      display: inline-block;
      padding-right: 15px;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 13px;
      color: $colorAccent2;
      font-family: $fontTitle1;
      letter-spacing: 0.18px;
      transition: opacity 0.35s ease;

      &:hover {
        opacity: .80;
      }

      &:last-of-type {
        padding-right: 0;
      }

      &.is-active {
        position: relative;
        color: $colorAccent1;

        //&:before {
        //  content: '';
        //  position: absolute;
        //  right: 0;
        //  bottom: -20px;
        //  left: 0;
        //  height: 4px;
        //  background-color: $colorAccent1;
        //  z-index: 1;
        //}
      }

      i {
        font-size: 20px;
      }
    }
  }
}
