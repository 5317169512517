// --------------------------------------------------
// Variables
// --------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

$colorAccent1: #64BD29;
$colorAccent2: #030000;
$colorAccent3: #42B853;
$colorAccent4: #ff4148;
$buttonColor: #69B42E;

$black: #000;
$white: #fff;
$dark: #5e5f61;
$darker: #222;
$light: #f9f9f9;
$grey: #b1b8c8;

$newGrey: #F1F2F5;
$newBlack: #3F3F3F;
$lightGrey: #C7C7CC;
$newColorAccent1: #7AC143;
$newTextColor: #57616E;
$borderGrey: #E4E6E7;

// Fonts
@font-face {
  font-family: 'gothammedium';
  src: url('/fonts/gothammedium_1-webfont.eot');
  src: url('/fonts/gothammedium_1-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothammedium_1-webfont.woff2') format('woff2'),
  url('/fonts/gothammedium_1-webfont.woff') format('woff'),
  url('/fonts/gothammedium_1-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothamlight_italic';
  src: url('/fonts/gothamlightitalic-webfont.eot');
  src: url('/fonts/gothamlightitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothamlightitalic-webfont.woff2') format('woff2'),
  url('/fonts/gothamlightitalic-webfont.woff') format('woff'),
  url('/fonts/gothamlightitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothamlight';
  src: url('/fonts/gothamlight-webfont.eot');
  src: url('/fonts/gothamlight-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothamlight-webfont.woff2') format('woff2'),
  url('/fonts/gothamlight-webfont.woff') format('woff'),
  url('/fonts/gothamlight-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothambook';
  src: url('/fonts/gothambook-webfont.eot');
  src: url('/fonts/gothambook-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothambook-webfont.woff2') format('woff2'),
  url('/fonts/gothambook-webfont.woff') format('woff'),
  url('/fonts/gothambook-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothambold';
  src: url('/fonts/gothambold-webfont.eot');
  src: url('/fonts/gothambold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothambold-webfont.woff2') format('woff2'),
  url('/fonts/gothambold-webfont.woff') format('woff'),
  url('/fonts/gothambold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothammedium';
  src: url('/fonts/gothammediumitalic-webfont.eot');
  src: url('/fonts/gothammediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothammediumitalic-webfont.woff2') format('woff2'),
  url('/fonts/gothammediumitalic-webfont.woff') format('woff'),
  url('/fonts/gothammediumitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothammedium';
  src: url('/fonts/gothammedium-webfont.eot');
  src: url('/fonts/gothammedium-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothammedium-webfont.woff2') format('woff2'),
  url('/fonts/gothammedium-webfont.woff') format('woff'),
  url('/fonts/gothammedium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothambook_italic';
  src: url('/fonts/gothambookitalic-webfont.eot');
  src: url('/fonts/gothambookitalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothambookitalic-webfont.woff2') format('woff2'),
  url('/fonts/gothambookitalic-webfont.woff') format('woff'),
  url('/fonts/gothambookitalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'gothambold_italic';
  src: url('/fonts/gothambolditalic-webfont.eot');
  src: url('/fonts/gothambolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/gothambolditalic-webfont.woff2') format('woff2'),
  url('/fonts/gothambolditalic-webfont.woff') format('woff'),
  url('/fonts/gothambolditalic-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'pf_square_sans_prothin_italic';
  src: url('/fonts/pfsquaresansprothinitalic_35491-webfont.eot');
  src: url('/fonts/pfsquaresansprothinitalic_35491-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansprothinitalic_35491-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansprothinitalic_35491-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansprothinitalic_35491-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_prothin';
  src: url('/fonts/pfsquaresansprothin_35490-webfont.eot');
  src: url('/fonts/pfsquaresansprothin_35490-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansprothin_35490-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansprothin_35490-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansprothin_35490-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_proregular';
  src: url('/fonts/pfsquaresansproregular_35489-webfont.eot');
  src: url('/fonts/pfsquaresansproregular_35489-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansproregular_35489-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansproregular_35489-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansproregular_35489-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_proMdIt';
  src: url('/fonts/pfsquaresanspromediumitalic_35488-webfont.eot');
  src: url('/fonts/pfsquaresanspromediumitalic_35488-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresanspromediumitalic_35488-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresanspromediumitalic_35488-webfont.woff') format('woff'),
  url('/fonts/pfsquaresanspromediumitalic_35488-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_promedium';
  src: url('/fonts/pfsquaresanspromedium_35487-webfont.eot');
  src: url('/fonts/pfsquaresanspromedium_35487-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresanspromedium_35487-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresanspromedium_35487-webfont.woff') format('woff'),
  url('/fonts/pfsquaresanspromedium_35487-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_proLtIt';
  src: url('/fonts/pfsquaresansprolightitalic_35486-webfont.eot');
  src: url('/fonts/pfsquaresansprolightitalic_35486-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansprolightitalic_35486-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansprolightitalic_35486-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansprolightitalic_35486-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_prolight';
  src: url('/fonts/pfsquaresansprolight_35485-webfont.eot');
  src: url('/fonts/pfsquaresansprolight_35485-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansprolight_35485-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansprolight_35485-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansprolight_35485-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_proitalic';
  src: url('/fonts/pfsquaresansproitalic_35484-webfont.eot');
  src: url('/fonts/pfsquaresansproitalic_35484-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansproitalic_35484-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansproitalic_35484-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansproitalic_35484-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_proXBlkIt';
  src: url('/fonts/pfsquaresansproextrablackitalic_35483-webfont.eot');
  src: url('/fonts/pfsquaresansproextrablackitalic_35483-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansproextrablackitalic_35483-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansproextrablackitalic_35483-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansproextrablackitalic_35483-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_proextra_black';
  src: url('/fonts/pfsquaresansproextrablack_35482-webfont.eot');
  src: url('/fonts/pfsquaresansproextrablack_35482-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansproextrablack_35482-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansproextrablack_35482-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansproextrablack_35482-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_probold_italic';
  src: url('/fonts/pfsquaresansprobolditalic_35481-webfont.eot');
  src: url('/fonts/pfsquaresansprobolditalic_35481-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansprobolditalic_35481-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansprobolditalic_35481-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansprobolditalic_35481-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'pf_square_sans_probold';
  src: url('/fonts/pfsquaresansprobold_35480-webfont.eot');
  src: url('/fonts/pfsquaresansprobold_35480-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/pfsquaresansprobold_35480-webfont.woff2') format('woff2'),
  url('/fonts/pfsquaresansprobold_35480-webfont.woff') format('woff'),
  url('/fonts/pfsquaresansprobold_35480-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

$fontTitle1: 'gothambold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTitle2: 'pf_square_sans_promedium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTitle3: 'pf_square_sans_proregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTitle4: 'pf_square_sans_proregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontTitle5: 'gothambold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontText: 'pf_square_sans_proregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontCaption: 'gothambook', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontBtn: 'gothambold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
